import React, {Fragment, useState, useEffect, useContext, useRef, createRef} from 'react';
import './videoCall.scss';

import JezDragAbleContainer from './../dragAbleContainer/dragAbleContainer';
import {Trans, useTranslation} from 'react-i18next';
import axios from 'axios';
import {isCustomer} from './../../util/roles'

import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Modal from '@material-ui/core/Modal';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AuthContext from '../../context/auth';
import SocketContext from '../../context/socket';
import {useHistory} from 'react-router-dom';
import {isHost, isModerator} from '../../util/roles';
import {toast} from 'react-toastify';
import JezVideoChat from './videoChat';
//eslint-disable-next-line
import Peer from 'peerjs';
import {useJezios} from "../../util/jezios";
import JezButton from "../button/button";

import helperDeviceStepOne from "../../assets/img/activate_media_sone.png";
import helperDeviceStepTwo from "../../assets/img/activate_media_stwo.png";
import {rand} from "../../util/tools";

const iceServers = {
    iceServers: [
        {
            url: 'turn:staging.jezzaber.com:5349',
            username: 'test',
            credential: 'test',
        },
    ],
};

const JezVideoCall = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const {auth} = useContext(AuthContext);
    const {socket} = useContext(SocketContext);
    const jezios = useJezios(auth, t);

    const [moderatedUser, setModeratedUser] = useState({username: 'Max Muster', id: 0});
    const [user, setUser] = useState({username: 'Max Muster', id: 0});
    const [visible, setVisible] = useState(false);
    const [online, setOnline] = useState({state: 0, uid: 0});
    const [options, setOptions] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [requiredDevices, setRequiredDevices] = useState({micro: true, video: false});

    const userRef = useRef();
    userRef.current = {
        data: user,
        set: setUser
    };

    const optionsRef = useRef();
    optionsRef.current = options;

    const streamRef = useRef(null);
    const rtcPeerConnection = useRef(null);
    const peerId = useRef(null);
    const remotePeerId = useRef(null);

    const video = createRef();

    async function getMedia() {
        let detectedMedia = {audio: false, video: false};
        try {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();

            for(const device of mediaDevices) {
                if(device.kind === 'audioinput') {
                    detectedMedia.audio = true;
                } else if(device.kind === 'videoinput') {
                    detectedMedia.video = true;
                }
            }

            //console.log('found Medias', mediaDevices, 'detected', detectedMedia);

            streamRef.current = await navigator.mediaDevices.getUserMedia(detectedMedia);
        } catch (err) {
            //toast.error(t(err));
            console.log(err);
        }
    }

    /**
     * HOOKS
     */

    useEffect(() => {
        getMedia();
    });

    useEffect(() => {
        if (socket !== null) {
            history.listen((location) => {
                if (auth && auth.roles.includes('ROLE_USER')) {
                    setHost(socket);
                }
            });

            if (auth && auth.roles && auth.roles.includes('ROLE_USER')) {
                setHost(socket);
            }

            //socket.on('room_created', () => {});

            socket.on('room_full', () => {
                //TODO implement not available at this time
            });

            socket.on(`room/${auth.id}/created`, async (userId) => {
                try {
                    const user = await axios.get(`/api/users/${userId}`);
                    setUser({username: user.data.username, id: user.data._id});
                    updateAccepted(false);
                    setVisible(true);
                } catch (errors) {
                    for (let err of errors.response.data.errors) {
                        toast.error(t(err.msg));
                    }
                }
            });

            socket.once(`call/${auth.id}`, async (userId, peer, options) => {
                console.log(`call: ${userId}`);

                try {
                    let user = await axios.get(`/api/users/${userId}`);

                    /**
                     * if call has a different uid
                     */
                    if (options.call !== user.data._id) {
                        try {
                            let userModerated = await axios.get(`/api/users/${options.call}`);
                            setModeratedUser({username: userModerated.data.username, id: userModerated.data._id});
                        }catch (e) {
                            let userModerated = await jezios.get(`locations/${options.call}`);
                            setModeratedUser({
                                username: userModerated.data.name,
                                id: userModerated.data._id,
                                picture: userModerated.data.images.length > 0 ? userModerated.data.images[0] : ''
                            });
                        }

                        /**
                         * If the user is a location
                         */
                        // if (!userModerated || userModerated.data.error) {
                        //     userModerated = await jezios.get(`locations/${options.call}`);
                        //     setModeratedUser({
                        //         username: userModerated.data.name,
                        //         id: userModerated.data._id,
                        //         picture: userModerated.data.images.length > 0 ? userModerated.data.images[0] : ''
                        //     });
                        // } else {
                        //     setModeratedUser({username: userModerated.data.username, id: userModerated.data._id});
                        // }

                        setOptions(options);
                    }

                    userRef.current.set({username: user.data.username, id: user.data._id});
                    //setUser({username: user.data.username, id: user.data._id});
                    updateAccepted(false);
                    setRequiredDevices({...requiredDevices, video: options.video});
                    setVisible(true);
                    remotePeerId.current = peer;
                    socket.emit(`io_leave_call_center_waiting_room`, {uid: auth.id});
                    // socket.emit(`call/available`, {
                    //     uid: {destination: null, source: auth.id},
                    //     state: 0
                    // });
                } catch (errors) {
                    if (errors.response) {
                        for (let err of errors.response.data.errors) {
                            toast.error(t(err.msg));
                        }
                    }
                }
            });

            /**
             * @deprecated
             */
            socket.on('left_room', () => {
                closeConnection();
                setVisible(false);
                updateAccepted(false);
            });

            socket.on(`room/${auth.id}/leave`, () => {
                closeConnection();
                setVisible(false);
                updateAccepted(false);
                if (isHost(auth) || isModerator(auth)) {
                    socket.emit(`io_join_call_center_waiting_room`, {uid: auth.id});
                }
            });

            socket.on('offer', async function (offer) {
                updateAccepted(true);
            });

            socket.on(`call/${auth.id}/available`, function (event) {
                if (auth.roles.includes('ROLE_USER')) {
                    setOnline(event);
                } else {
                    socket.emit(`call/available`, {
                        uid: {destination: event.uid, source: auth.id},
                        state: userRef.current.data.id === 0
                    });
                }
            });

            if (isCustomer(auth)) {
                socket.on(`io_call_is_available_${user.id}`, function (event) {
                    if ((userRef.current.data.id === event.uid && !userRef.current.data.moderated) || (event.mod && userRef.current.data.moderated)) {
                        setOnline(event);
                    }
                });

                socket.on(`io_call_is_available_${auth.id}`, function (event) {
                    if ((userRef.current.data.id === event.uid && !userRef.current.data.moderated) || (event.mod && userRef.current.data.moderated)) {
                        setOnline(event);
                    }
                });
            }
        }

        return () => {
            if (socket !== null) {
                socket.off(`call/${auth.id}`);
                socket.off(`call/${auth.id}/available`);
                socket.off(`room/${auth.id}/leave`);
                socket.off(`offer`);
                socket.off(`left_room`);
                socket.off(`room/${auth.id}/created`);
                socket.off(`room_full`);

                if (isCustomer(auth)) {
                    socket.off(`io_call_is_available_${user.id}`);
                    socket.off(`io_call_is_available_${auth.id}`);
                }
            }
        };
    }, [socket, history, user]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateAccepted = (value) => {
        if (value) {
            if (isHost(auth) || isModerator(auth)) {
                document.getElementById('incoming-video-call-host').classList.add('hidden');
            } else {
                document.getElementById('incoming-video-call-user').classList.add('hidden');
            }

            document.getElementById('video-call-connection').classList.remove('hidden');
        } else {
            if (isHost(auth) || isModerator(auth)) {
                document.getElementById('incoming-video-call-host').classList.remove('hidden');
            } else {
                document.getElementById('incoming-video-call-user').classList.remove('hidden');
            }

            document.getElementById('video-call-connection').classList.add('hidden');
        }
    };

    const setHost = async (socket) => {
        let isUser = true;
        let data = window.location.href.match(/\/host\/([^/]+)\/profile/);

        setOnline(false);

        if (!data) {
            isUser = false;
            data = window.location.href.match(/\/locations\/([^/]+)/);
        }

        if (data && data.length === 2 && data[1] !== user.id) {
            try {
                let response = null;

                if (isUser) {
                    response = await jezios.get(`hosts/${data[1]}`);
                } else {
                    response = await jezios.get(`locations/${data[1]}`);
                }

                if (socket) {
                    socket.emit(`call/available`, {
                        uid: {destination: data[1], source: auth.id, isLocation: !isUser},
                        state: 1
                    });
                }

                setUser({
                    username: response.data.username ? response.data.username : response.data.name,
                    id: response.data._id,
                    moderated: !isUser || response.data.videoChat === 'moderated',
                    isLocation: !isUser,
                    picture: response.data.images && response.data.images.length > 0 ? response.data.images[0] : ''
                });
            } catch (errors) {
                if (errors.response instanceof Array) {
                    for (let err of errors.response.data.errors) {
                        toast.error(t(err.msg));
                    }
                } else {
                    toast.error(t(errors.response));
                }
            }
        } else if (data === null && user.id !== 0) {
            setUser({username: '', id: 0});
        }
    };

    const createPeerConnection = (callback) => {
        rtcPeerConnection.current = new Peer({
            host: window.location.hostname === 'localhost' ? 'localhost' : 'peer.jezzaber.com',
            port: 9000,
            path: '/peer',
            debug: 3,
            config: iceServers,
        });

        rtcPeerConnection.current.on('open', callback);
        rtcPeerConnection.current.on('call', function (call) {
            call.on('stream', (stream) => {
                document.getElementById('remote-video').srcObject = stream;
                updateAccepted(true);
            });
            call.answer(streamRef.current);
        });
    };

    const onAcceptCall = async () => {
        createPeerConnection((id) => {
            peerId.current = id;
            const call = rtcPeerConnection.current.call(remotePeerId.current, streamRef.current);
            call.on('stream', (stream) => {
                document.getElementById('remote-video').srcObject = stream;
                updateAccepted(true);
            });
        });
        updateAccepted(true);
    };

    const onCall = async (video, contactType) => {
        await navigator.getUserMedia({ audio: true, video: video }, (stream) => {
            createPeerConnection((id) => {
                peerId.current = id;
                socket.emit(`call`, auth.id, online.uid, peerId.current, {
                    call: userRef.current.data.id,
                    video: video,
                    info: [{label: t('contact_type'), value: contactType}]
                });
            });
            setVisible(true);
            setRequiredDevices({...requiredDevices, video: video});
            updateAccepted(false);
        }, (err) => {
            setModalOpen(true);
            updateAccepted(false);
            setRequiredDevices({...requiredDevices, video: video});
        });
    };

    const closeConnection = () => {
        if(rtcPeerConnection.current != null) {
            rtcPeerConnection.current.destroy();
            rtcPeerConnection.current = null;
        }
    };

    const onEndCall = async () => {
        console.log(`end ${online.uid} | ${userRef.current.data.id}`);
        socket.emit('leave_room', online.uid ? online.uid : userRef.current.data.id);
        closeConnection();
        if (isHost(auth) || isModerator(auth)) {
            socket.emit(`io_join_call_center_waiting_room`, {uid: auth.id});
        }
        setVisible(false);
    };

    const modalHandleClose = () => {
        setModalOpen(false);
    };

    return (
        <Fragment>
            {!visible && online.state && auth && auth.roles && auth.roles.includes('ROLE_USER') && user.id !== 0 && (
                <div className={'initiate-call'} data-video={0} data-contact-type={t('other')} onClick={(e) => {
                    let target = e.target;
                    if(target.tagName === 'svg') {
                        target = target.parentNode;
                    }
                    onCall(
                        parseInt(target.getAttribute('data-video')) === 1,
                                target.getAttribute('data-contact-type')
                    );
                }}>
                    <CallIcon/>
                </div>
            )}
            <JezDragAbleContainer
                title={(auth && auth.roles && auth.roles.includes('ROLE_USER')
                        ? t('call_user')
                        : t('incoming_call')
                ).replace('{username}', user.username)}
                visible={visible}
            >
                <div className={`video-call-container`}>
                    {auth && auth.roles && (isHost(auth) || isModerator(auth)) && (
                        <div id={'incoming-video-call-host'} className={'incoming-video-call'}>
                            <div className={'image-container'}>
                                <img
                                    className='img-circle'
                                    src='https://placeimg.com/400/400/people'
                                    alt=''
                                    width='135'
                                />
                            </div>
                            <div className={'call-user'}>{user.username}</div>
                            <div className={'call-action-container'}>
                                <span className='phone' onClick={onAcceptCall} onTouchStart={onAcceptCall}>
                                    <CallIcon className={'accept-video-call icon'}/>
                                    <span className='cover'>
                                        <span className='wave one'/>
                                        <span className='wave two'/>
                                        <span className='wave three'/>
                                    </span>
                                </span>
                                <span className={'reject-phone'} onClick={onEndCall} onTouchStart={onEndCall}>
                                    <CallEndIcon className={'reject-video-call icon'}/>
                                    <span className='cover'>
                                        <span className='wave one'/>
                                        <span className='wave two'/>
                                        <span className='wave three'/>
                                    </span>
                                </span>
                            </div>
                            {auth && isModerator(auth) && (
                                <div className={'moderated-host'}>
                                    <div className={'call-user-host'}>
                                        <Trans>user_call_host</Trans>
                                    </div>
                                    <div className={'image-container'}>
                                        {moderatedUser.id !== 0 && (
                                        <img
                                            className='img-circle'
                                            src={moderatedUser.picture ? `/api/pictures/${moderatedUser.picture}` : `/api/hosts/${moderatedUser.id}/avatar/single`}
                                            alt=''
                                            width='135'
                                        />
                                        )}
                                    </div>
                                    <div className={'call-user'}>{moderatedUser.username}</div>
                                    {options.info && options.info.length > 0 && (
                                        <>
                                            <div className={'call-user-host-data'}>
                                                <Trans>called_user_field</Trans>
                                            </div>
                                            <ul className={'data-list'}>
                                                {options.info.map((item) => (<li key={rand()}>
                                                    <div className={'data-label'}>{item.label}:&nbsp;</div>
                                                    <div className={'data-value'}>{item.value}</div>
                                                </li>))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {auth && auth.roles && auth.roles.includes('ROLE_USER') && (
                        <div id={'incoming-video-call-user'} className={'incoming-video-call'}>
                            <div className={'image-container'}>
                                <div className='call-animation'>
                                    {user.id !== 0 && (
                                        <img
                                            className='img-circle'
                                            src={user.picture ? `/api/pictures/${user.picture}` : `/api/hosts/${user.id}/avatar/single`}
                                            alt=''
                                            width='135'
                                        />
                                    )}
                                </div>
                                <div className={'call-user'}>{user.username}</div>
                                <div className={'call-action-container'}>
                                    <span className={'reject-phone'} onClick={onEndCall} onTouchStart={onEndCall}>
                                        <CallEndIcon className={'reject-video-call icon'}/>
                                        <span className='cover'>
                                            <span className='wave one'/>
                                            <span className='wave two'/>
                                            <span className='wave three'/>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div id={'video-call-connection'} className={`video-call-connection hidden ${requiredDevices.video ? '' : 'phone-only'}`}>
                        <div className={'call-user'}>
                            <div className={'calling-actions'}>
                                <span className={'call-end'} onClick={onEndCall} onTouchStart={onEndCall} title={t('call_end')}>
                                    <CallEndIcon className={'icon'}/>
                                </span>
                            </div>
                            {user.username}
                        </div>
                        <div className={'video-container'}>
                            <div className={'video-stream'}>
                                <video
                                    ref={video}
                                    playsInline
                                    autoPlay
                                    width='419'
                                    height='470'
                                    id='remote-video'
                                ></video>
                                <div className={'anti-click-overlay'}></div>
                            </div>
                        </div>
                        <JezVideoChat user={online.uid ? {data: {id: online.uid} } : userRef.current} onClick={requiredDevices.video ? false : onEndCall}/>
                    </div>
                </div>
            </JezDragAbleContainer>
            <Modal
                open={modalOpen}
                onClose={modalHandleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={'video-call-exception-modal'}>
                    <h3 className={'headline'}><Trans>device_permission_required</Trans></h3>
                    <ul>
                        {requiredDevices.micro && (<li><Trans>please_get_audio_permission</Trans></li>)}
                        {requiredDevices.video && (<li><Trans>please_get_video_permission</Trans></li>)}
                    </ul>

                    <div className={'video-call-help'}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Trans>how_activate_my_mic_camera</Trans>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <ol>
                                        <li>
                                            <Trans>get_permission_step_one</Trans>
                                            <div className={'helper-image-container'}>
                                                <img src={helperDeviceStepOne} alt={t('helper_step_one_image')} />
                                            </div>
                                        </li>
                                        <li>
                                            <Trans>get_permission_step_two</Trans>
                                            <div className={'helper-image-container'}>
                                                <img src={helperDeviceStepTwo} alt={t('helper_step_two_image')}/>
                                            </div>
                                        </li>
                                        <li>
                                            <Trans>get_permission_step_three</Trans>
                                        </li>
                                        <li>
                                            <Trans>get_permission_step_four</Trans>
                                        </li>
                                        <li>
                                            <Trans>get_permission_step_five</Trans>
                                        </li>
                                    </ol>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div>
                        <JezButton margin small onClick={modalHandleClose}><Trans>label_ok</Trans></JezButton>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default JezVideoCall;
