import {isAdmin, isModerator} from "../../util/roles";
import React, {Fragment, useContext, useEffect, useState} from "react";
import AuthContext from "../../context/auth";
import JezTextEditor from "../texteditor/texteditor";
import PropTypes from "prop-types";
import './ModifyAblePageContent.scss';
import {Trans, useTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {FaEdit, FaPlusSquare} from "react-icons/all";
import JezHeading from "../heading/heading";
import Teaser from "../teaser/teaser";

const ModifyAblePageContent = ({context, ...props}) => {
    /**
     * CONTEXT *
     */
    const { auth } = useContext(AuthContext);
    const {t} = useTranslation();

    /**
     * STATES *
     */
    const [state, setState] = useState({content: '', teaser: ''});
    const [editState, setEditState] = useState({content: '', teaser: ''});
    const [editMode, setEditMode] = useState(false);

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * FUNCTIONS *
     */

    const change = (field, value) => {
        setEditState({...editState, [field]: value});
    };

    const cancel = () => {
        setEditState(state);
        setEditMode(false);
    }

    const fetch = async () => {
        try {
        const response = await axios.get(
            `/api/content/${context}`
        );

        setState(response.data);
        setEditState(response.data);

        } catch (errors) {
            console.log(errors);
        }
    }

    const edit = (value) => {
        setEditMode(value)
    }

    const save = async () => {
        try {
            await axios.put(
                `/api/content/${context}`,
                editState,
                {
                    headers: {'x-auth-token': auth.token},
                }
            );

            toast.success(t('content_saved'));
            setState(editState);
            edit(false);
        } catch (errors) {
            console.log(errors);
        }
    }

    if(isAdmin(auth) && editMode) {
        return (
            <div className={'modify-able-page-content-container'}>
                <JezHeading variant='h4' styles={{margin: '8px 0 2px 0', fontSize: '18px'}}>
                    <Trans>lbl_Inhalt</Trans>
                </JezHeading>
                <JezTextEditor
                    maxHeight={200}
                    required
                    id='content'
                    onChange={(e) => change(e.target.value)}
                    name='content'
                    data={editState.content}
                    setState={(val) => {
                        change('content', val);
                    }}
                    state={editState.content}
                />
                <br />
                <JezHeading variant='h4' styles={{margin: '8px 0 0 0', fontSize: '18px'}}>
                    <Trans>lbl_teaser</Trans><span className={'optional'}><Trans>lbl_optional</Trans></span>
                </JezHeading>
                <div className={'description'}>
                    <small><Trans>lbl_teaser_help</Trans></small>
                </div>
                <JezTextEditor
                    required
                    id='teaser'
                    onChange={(e) => change(e.target.value)}
                    name='teaser'
                    data={editState.teaser}
                    setState={(val) => {
                        change('teaser', val);
                    }}
                    state={editState.teaser}
                />
                <button onClick={save} className={`btn primary save`}><Trans>btn_save</Trans></button>
                <button onClick={cancel} className={`btn primary error cancel`}><Trans>btn_cancel</Trans></button>
            </div>
        );
    } else {
        return (<div className={'modify-able-page-content-container'}>
            {state.content.length > 0 && (<Fragment>
                {state.teaser.length > 0 && (<Teaser
                    teaser={(<div dangerouslySetInnerHTML={{__html: state.teaser}} />)}
                >
                    <div
                        dangerouslySetInnerHTML={{__html: state.content}}
                    />
                </Teaser>)}
                {state.teaser.length === 0 && (
                    <div
                        dangerouslySetInnerHTML={{__html: state.content}}
                    />
                )}
                {isAdmin(auth) && !editMode && (<div className={'edit'} onClick={() => {edit(true)}}><FaEdit /><span><Trans>btn_edit</Trans></span></div>)}
            </Fragment>)}

            {state.content.length === 0 && (<Fragment>
                {isAdmin(auth) && !editMode && (<div className={'edit'} onClick={() => {edit(true)}}><FaPlusSquare /><span><Trans>btn_add_content</Trans></span></div>)}
            </Fragment>)}

        </div>);
    }
}

ModifyAblePageContent.propTypes = {
    context: PropTypes.string.isRequired
};

ModifyAblePageContent.defaultProps = {};

export default ModifyAblePageContent;