import React, {useEffect, useState, Fragment} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {makeStyles, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {rand} from "../../util/tools";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {AiOutlineSearch, FaInfoCircle} from 'react-icons/all';
import JezCheckbox from "../checkbox/checkbox";

const useStyles = makeStyles((theme) => ({
    table: {
        color: '#fff'
    },
    container: {
        position: 'relative',
        minHeight: 500
    },
    loader: {
        color: '#b4ab6e',
        position: 'relative',
        textAlign: 'center'
    },
    loaderTime: {
        height: 10,
        lineHeight: 0,
        margin: '-18px 0 0 0',
        display: 'inline-block',
        bottom: -12,
        position: 'relative'
    },
    loaderTimeContainer: {
        height: 10,
        lineHeight: 0,
        margin: '-18px 0 0 0',
    },
    header: {
        fontWeight: 'bold',
        '&:hover': {
            '& *': {
                opacity: 1
            }
        }
    },
    pagination: {
        listStyleType: 'none',
        textAlign: 'right',
        margin: '8px 20px',
        padding: 0,
        '& li': {
            width: 15,
            textAlign: 'center',
            display: 'inline-block',
            padding: '3px 4px',
            margin: '0 3px',
            color: '#b4ab6e',
        }
    },
    currentPage: {
        background: '#b4ab6e',
        color: '#fff !important'
    },
    clickAble: {
        cursor: 'pointer',
        borderRadius: 3,
        border: '1px solid #b4ab6e',
        '&:hover': {
            background: '#b4ab6e',
            color: '#fff'
        }
    },
    search: {
        padding: '5px 10px !important',
        width: '100%',
        border: '0 !important',
        backgroundColor: 'rgba(180, 171, 110, 0.3) !important',
        outline: 'none',
        borderBottom: '1px solid #b4ab6e !important',
        color: '#b4ab6e',
        boxSizing: 'border-box'
    },
    noMatch: {
        padding: '25px 0 15px 0',
        color: '#b4ab6e',
        fontSize: 20,
        textAlign: 'center',
        cursor: 'default'
    },
    headerOptions: {
        margin: '0 5px',
        opacity: '1 !important',
        '& *': {
            fontSize: 14,
            verticalAlign: 'bottom',
            cursor: 'pointer',
            opacity: 0
        }
    },
    headerElementActive: {
        opacity: '1 !important',
        fontSize: 20,
        '& *': {
            opacity: 1
        }
    },
    searchContainer: {
        position: 'relative'
    },
    filterContainer: {
        position: 'absolute',
        right: 5,
        top: 5,
        color: '#b4ab6e',
        zIndex: 1000
    },
    filterName: {
        border: '1px solid #b4ab6e !important',
        padding: '5px 10px',
        background: 'rgba(0,0,0,0.8)',
        boxShadow: 'rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px',
        borderRadius: 3,
        margin: 0,
        listStyleType: 'none',
        '& li': {
            margin: '0 0 5px 0',
            '& ul': {
                listStyleType: 'none',
                padding: 0,
                margin: '5px 0 5px 15px'
            }
        }
    },
    filterLabel: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'right',
        textTransform: 'uppercase',
        cursor: 'pointer'
    },
    columnFilterName: {
        fontWeight: 'bold',
    }
}));

const StyledTableContainer = withStyles((theme) => ({
    root: {
        border: '1px solid #b4ab6e',
        borderRadius: 3,
        overflow: 'visible'
    },
}))(TableContainer);

const StyledTableHead = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(180, 171, 110, 0.7)',
        color: '#fff',
        '& th': {
            color: '#fff',
            border: 0
        }
    },
}))(TableHead);

const StyledTableBody = withStyles((theme) => ({
    root: {
        color: '#fff',
        '& tr:nth-child(even)': {
            position: 'relative',
            backgroundColor: 'rgba(180, 171, 110, 0.3)',
        },
        '& th, & td': {
            color: '#fff',
            border: 0,
            height: 30,
            verticalAlign: 'top',
        }
    },
}))(TableBody);

const JezTable = ({rows, fetch, pages, page, columns, loading}) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const [initial, setInitial] = useState([]);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [columnFilter, setColumnFilter] = useState([]);
    const [filter, setFilter] = useState({});
    const [filterOpen, setFilterOpen] = useState(false);

    const {t} = useTranslation();

    const fetchData = (rows) => {
        let list = [];

        for(let ii = 0; ii < rows.length; ii++) {
            list.push({...rows[ii], id: rand()});
        }

        setData(list);
        setInitial(list);
    };

    useEffect(() => {
        fetchData(rows);
    }, [rows]);

    useEffect(() => {
        update()
    }, [filter]);

    const renderCell = (column, row) => {
        let content = '';

        if(row[column.name]) {
            content = row[column.name];
        }

        if(column.render) {
            content = column.render(row)
        }

        const dataCellOptions = column.dataCellOptions ? column.dataCellOptions : {};

        return (<TableCell style={column.lineBreak === undefined || column.lineBreak === true ? {} : {whiteSpace: 'nowrap'}} key={rand()} {...dataCellOptions}>{content}</TableCell>);
    };

    const renderHeader = (column) => {
        const options = column.options ? column.options : {};

        return (<TableCell key={rand()} className={classes.header} {...options}>
            {column.title ? column.title : ''}
            <span className={classes.headerOptions}>
                <AiOutlineSearch className={columnFilter.indexOf(column.name) !== -1 && classes.headerElementActive} onClick={() => { updateFilter(column.name) }}/>
            </span>
        </TableCell>);
    };

    const createPagination = (itemCount, page) => {
        let list = [];
        let pages = Math.ceil(itemCount/limit);
        let displayRange = 3;

        if(pages < 2) {
            return list;
        }

        for(let ii = 0; ii < pages; ii++) {
            list.push({
                page: ii + 1,
                current: page !== undefined ? ii === page - 1 : ii === currentPage,
                click: true
            });
        }

        let range = displayRange - currentPage;
        let from = currentPage - displayRange < 0 ? 0 : currentPage - displayRange;
        let to = currentPage + displayRange + (range > 1 ? range - 1 : 0);

        list = list.slice(from, to + 1);

        if(list[0] && list[0].page > 2) {
            list = [{
                page: '...',
                current: false,
                click: false}].concat(list)
        }

        if(list[0] && list[0].page !== 1) {
            list = [{
                page: 1,
                current: false,
                click: true}].concat(list)
        }

        const length = list.length - 1;
        if(list[length] && list[length].page < pages - 1) {
            list = list.concat([{
                page: '...',
                current: false,
                click: false}])
        }

        if(list[length] && list[length].page !== pages) {
            list = list.concat([{
                page: pages,
                current: false,
                click: true}])
        }

        return list;
    };

    const updatePage = (page) => {
        if(!!fetch) {
            fetch(page, filter);

            return;
        }

        setCurrentPage(page - 1);
    };

    const search = (value) => {
        checkFilter('over_all', {key: value, single: true});
    };

    const updateFilter = (field) => {
        const index = columnFilter.indexOf(field);

        if(index === -1) {
            setColumnFilter([...columnFilter, field]);
        } else {
            let update = [...columnFilter];
            update.splice(index, 1);
            setColumnFilter(update);
        }
    };

    const update = () => {
        if(!!fetch) {
            fetch(1, filter);

            return;
        }

        let list = initial;
        for(const filterKey in filter) {
            const data = filter[filterKey];

            const regex = new RegExp(data.join('|'), 'i');
            list = list.filter((item) => {
                let fields = columnFilter.length > 0 ? columnFilter : Object.keys(item);
                fields = Object.keys(item).indexOf(filterKey) === -1 ? fields : [filterKey];
                for(const key of fields) {
                    let col = columns.filter((item) => { return item.name ===  key});
                    if(col[0] && col[0].search) {
                        return col[0].search(data, regex, item);
                    } else if(item[key] instanceof Array && item[key].filter(item => item.match(regex) !== null).length > 0) {
                        return true;
                    } else if(String(item[key]).match(regex) !== null) {
                        return true;
                    }
                }
                return false;
            });
        }

        setData(Object.values(filter).length > 0 ? list : initial);
    };

    const checkFilter = (columnName, item) => {
        let update = {...filter};
        if(!update[columnName] || item.single) {
            update[columnName] = [];
        }

        const index = update[columnName].indexOf(item.key);

        if(index === -1) {
            if(item.key.trim().length > 0) {
                update[columnName].push(item.key);
            }
        } else {
            update[columnName].splice(index, 1);
        }

        if(update[columnName].length === 0) {
            delete update[columnName];
        }

        setFilter(update);
    };

    const addFilter = (column) => {
        if(!column.filter) {
            return (<Fragment />);
        }

        return (
            <li key={rand()}>
                <span className={classes.columnFilterName}>{column.title}</span>
                <ul key={rand()}>
                    {column.filter.entries.map(item => (<li key={rand()}><JezCheckbox checked={filter[column.name] && filter[column.name].indexOf(item.key) !== -1} onChange={(e) => {checkFilter(column.name, item)}} value={item.key} label={item.label}/></li>))}
                </ul>
            </li>
        );
    };

    return (
        <div className={classes.container}>
            {(
                <StyledTableContainer>
                    <div className={classes.searchContainer}>
                        <input type={'text'} onKeyUp={(e) => { search(e.target.value) }} className={classes.search} placeholder={t('label_table_search')}/>
                        {columns.filter(item => item.filter).length > 0 && (
                            <div className={classes.filterContainer}>
                                <span onClick={() => setFilterOpen(!filterOpen) } className={classes.filterLabel}>{t('label_filter')}</span>
                                {filterOpen && (
                                    <ul className={classes.filterName}>
                                        {columns.map(item => addFilter(item))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                    <Table className={classes.table} size="small">
                        <StyledTableHead>
                            <TableRow key={rand()}>
                                {columns && columns.map((item) => { return renderHeader(item) })}
                            </TableRow>
                        </StyledTableHead>
                        <StyledTableBody>
                            {data.slice(currentPage * limit, (currentPage + 1) * limit).map((row) => (
                                <TableRow key={rand()}>
                                    {columns && columns.map((item) => { return renderCell(item, row) })}
                                </TableRow>
                            ))}
                        </StyledTableBody>
                    </Table>
                    {initial && initial.length > 0 && data.length === 0 && (<div className={classes.noMatch}><Trans>label_no_matches</Trans></div>)}
                    {!loading && initial && initial.length === 0 && data.length === 0 && (<div className={classes.noMatch}><Trans>label_no_data</Trans></div>)}
                    {loading && (<div className={classes.noMatch}><div className={classes.loader}><Trans>loader_label</Trans>&nbsp;<div className={classes.loaderTimeContainer}><div id={'loader-time'} className={classes.loaderTime} /></div></div></div>)}
                    <ul className={classes.pagination} key={rand()}>
                        {data && createPagination(pages !== undefined ? pages : data.length, page).map((item) => (<li onClick={() => { if(item.click) { updatePage(item.page) } }} className={`${item.click && classes.clickAble} ${item.current && classes.currentPage}`} key={rand()}>{item.page}</li>))}
                    </ul>
                </StyledTableContainer>
            )}
        </div>
    )
};

JezTable.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
};

export default JezTable;