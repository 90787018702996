import Languages from '@cospired/i18n-iso-languages';
import {Grid, withStyles} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DoneIcon from '@material-ui/icons/Done';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import TimerSharpIcon from '@material-ui/icons/TimerSharp';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import axios from 'axios';
import Countries from 'i18n-iso-countries';
import moment from 'moment';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AiFillHeart, BsImage, BsTextCenter, FaRegMoneyBillAlt} from 'react-icons/all';
import {Link, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import JezButton from '../components/button/button';
import JezCenter from '../components/center/center';
import JezErrorMessage from '../components/errormessage/errormessage';
import JezHeading from '../components/heading/heading';
import JezPageInfo from "../components/pageinfo/pageinfo";
import PasswordForm from "../components/passwordform/passwordform";
import JezSelect from '../components/select/select';
import JezTextEditor from '../components/texteditor/texteditor';
import AuthContext from '../context/auth';
import handleErrors from "../util/handleErrors";
import {isCustomer, isHost, isModerator} from "../util/roles";
import JezContainer from '../components/container/container';
import defaultConfig from "../config/default.json";
import JezImageUpload from "../components/imageUpload/imageUpload";
import {buildFormData, stringFormat} from "../util/tools";
import JezProfileBodyProperties from "../sections/forms/profile/ProfileBodyProperties";
import JezProfilePersonalData from "../sections/forms/profile/JezProfilePersonalData";
import JezProfileLocation from "../sections/forms/profile/ProfileLocation";
import JezProfileMeetPoint from "../sections/forms/profile/ProfileMeetPoint";
import JezTextArea from "../components/textarea/textarea";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
Languages.registerLocale(require("@cospired/i18n-iso-languages/langs/de.json"));

const useStyles = makeStyles((theme) => ({
    strong: {
        color: '#fff',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '1.5384',
    },
    listStyleNone: {
        listStyle: 'none',
    },
    rejectedValue: {
        '& label': {
            color: '#900 !important'
        },
        '& input': {
            border: '1px solid #900 !important',
        }
    },
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    marginAuto: {
        margin: 'auto',
    },
    descriptionEditor: {},
    check: {
        color: '#888',
        fontSize: 30,
        '& svg': {
            color: '#b4ab6e',
        },
    },
    margin: {
        marginTop: 12,
    },
    pendingDataContainer: {
        position: 'relative',
    },
    statusIcon: {
        margin: '0 0 0 5px',
         color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    statusIconChk: {
        position: 'static',
        left: '-17px',
        top: '12px',
        color: '#888',
        zIndex: 10,
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    reviewAcceptIcon: {
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    reviewRejectIcon: {
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    reviewAcceptIconChk: {
        position: 'absolute',
        left: '-17px',
        top: '12px',
        color: '#888',
        zIndex: 10,
        cursor: 'pointer',
    },
    reviewRejectIconChk: {
        position: 'absolute',
        left: '-37px',
        top: '12px',
        color: '#888',
        zIndex: 10,
        cursor: 'pointer',
    },
    reviewChecked: {
        color: '#807154',
    },
    reviewImageContainer: {
        position: 'absolute',
        right: '5px;',
        backgroundColor: '#0000008c',
    },
    unit: {
        verticalAlign: 'top',
        color: '#888'
    },
    iconContainer: {
        position: 'absolute',
        right: '5px',
        top: '26px',
        color: '#888',
        zIndex: 10,
    },
    iconContainerExtra: {
        position: 'absolute',
        right: '5px',
        top: '10px',
        color: '#888',
        zIndex: 9000,
    },
    popover: {
        padding: '5px 10px',
    },
    popoverHeadline: {
        color: '#b4ab6e',
        margin: '5px 10px'
    },
    popoverTextArea: {
        background: 'transparent',
        border: '1px solid #b4ab6e',
        boxSizing: 'border-box',
        padding: '5px 10px',
        margin: '5px 10px',
        color: '#fff'
    },
    popoverSubmit: {
        fontSize: '12px',
        margin: '0 0 5px 10px'
    },
    moderatorMessage: {
        color: '#eee',
        padding: '5px 10px;'
    },
    chEditorLength: {
        color: '#888',
        position: 'absolute',
        bottom: '10px',
        right: '10px'
    },
    subList: {
        listStyleType: 'none',
        margin: '0'
    },
    sectionHeadline: {
        margin: '50px 0 15px 0 !important'
    },
    sectionImageHeadline: {
        margin: '50px 0 33px 0 !important'
    },
    headlineWidthSub: {
      position: 'relative'
    },
    subHeadline: {
        position: 'absolute',
        bottom: -11,
        right: 0,
        left: 0,
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '10px',
        whiteSpace: 'nowrap'
    },
    descriptionError: {
        color: '#900'
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontFamily: '"Lato", serif',
                "& input": {
                    "&:-webkit-autofill": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                    "&:-webkit-autofill:focus": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                    "&:-webkit-autofill:hover": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                },
            },
        },
    }
}));

const JezzPopover = withStyles({
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        background: 'rgba(0,0,0,0.4)',
        border: `1px solid #b4ab6e !important`,
        borderRadius: '3px',
    },
})(Popover);

const ProfileForm = ({ history, match: { params } }) => {

    /**
     * CSS
     */
    const classes = useStyles();

    const {t} = useTranslation();
    let {hostId} = useParams();
    const config = defaultConfig;

    /**
     * CONTEXT
     */
    const {auth, setAuth} = useContext(AuthContext);

    /**
     * STATE
     */
    const [anchorEl, setAnchorEl] = useState(null); //Moderator Notice Popover
    //const [languagePopoverAnchorEl, setLanguagePopoverAnchorEl] = useState(null); //Moderator Notice Popover
    const [moderated, setModerated] = useState({id: 0, notice: '', field: ''});
    //const [validation, setValidation] = useState({error: false, errorMessage: {}});
    const [profileState, setProfileState] = useState({pending: false, filledRequiredFields: false, verifiedRequiredFields: false, hasRejections: false});
    const [state, setState] = useState({
        whatsapp: 0,
        public_services: [],
        hidden_services: [],
        unwanted_services: [],
        size: 0,
        languages: [],
        cup_size: '',
        avatar: [],
        images: [],
        weight: 0,
        phone: '',
        hair_color: '',
        eye_color: '',
        cup_type: '',
        nationality: '',
        city: '',
        location: '',
        radius: 0,
        birth_year: '',
        meet: null,
        meet_hotel: null,
        meet_home: null,
        meet_agreement: null,
        meet_locations: [],
        gender: '',
        password: '',
        sex_type: [],
        url:'',
        url_name: '',
        places: [],
        availability: 1,
        availability_reason: '',
        prices: ''
    });
    const [description, setDescription] = useState('');
    const [user, setUser] = useState({roles: [], username: '', email: '', google_email: ''});
    const [services, setServices] = useState([]);
    const [verified, setVerified] = useState([]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let parentRef = React.useRef(user);
    let descriptionRef = React.useRef({data: description, set: setDescription});

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!auth) {
        history.push('/login');
    }

    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    const handleClick = (event) => {
        const id = event.currentTarget.getAttribute('data-id');
        const notice = event.currentTarget.getAttribute('data-notice');
        const fieldName = event.currentTarget.getAttribute('data-field');

        setModerated({id: id, notice: notice, field: fieldName});

        setAnchorEl(event.currentTarget);
    };

    const checkboxHandler = (e) => {
        setState({...state, [e.target.name]: e.target.checked});
    };

    // const languagePopoverHandleClick = (event) => {
    //     setLanguagePopoverAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
        //setLanguagePopoverAnchorEl(null);
    };

    if (auth && ((auth.roles && !isModerator(auth)) || !hostId)) {
        hostId = auth.id;
    }

    /**
     * CONTROLS
     */
    const change = (e) => {
        setState({...state, [e.target.name]: e.target.value});

        let newVerified = {...verified};

        if(newVerified[e.target.name] && newVerified[e.target.name].value) {
            newVerified[e.target.name].value = e.target.value;
        } else {
            newVerified[e.target.name] = {status: 0, notice: '', value: e.target.value}
        }

        setVerified(newVerified);
    };

    const changeDescription = (name, value) => {
        value = value.substr(0, parseInt(user && user.permissions ? user.permissions.descriptionMaxLength : 500));
        setState({...state, [name]: value});
        setDescription(value);
    };

    /**
     * CONTROLS
     */
    const changeUser = (e) => {
        setUser({...user, [e.target.name]: e.target.value});

        let newVerified = {...verified};

        if(newVerified[e.target.name] && newVerified[e.target.name].value) {
            newVerified[e.target.name].value = e.target.value;
        } else {
            newVerified[e.target.name] = {status: 0, notice: '', value: e.target.value}
        }

        setVerified(newVerified);
    };

    const changeMeetLocation = (e) => {
        if (e.target.checked) {
            setState({...state, places: [...state.places, e.target.value]});
        }
        else {
            setState({...state, places: state.places.filter((item) => item !== e.target.value)});
        }
    };

    const changeMeet = (e) => {
        setState({...state, [e.target.name]: e.target.checked});
        let newVerified = {...verified};

        if(newVerified[e.target.name] && newVerified[e.target.name].value) {
            newVerified[e.target.name].value = e.target.checked ? 1: 0;
        } else {
            newVerified[e.target.name] = {status: 0, notice: '', value: e.target.checked ? 1 : 0}
        }

        setVerified(newVerified);
    };

    const addImage = (imageList) => {
        const images = imageList.map((item) => {if(item.status === undefined) { item.status = 0; } return item; });
        setState({...state, images: images});
    };

    const updateFieldState = async function(e) {
        let id = e.target.getAttribute('data-id');
        let status = e.target.classList.contains('data-accept');

        if (!id) {
            id = e.target.parentNode.getAttribute('data-id');
            status = e.target.parentNode.classList.contains('data-accept');
        }

        try {
            await axios.put(
                `/api/moderation/data/${id}`,
                {status: status ? 'verified' : 'denied'},
                {
                    headers: {'x-auth-token': auth.token},
                }
            );

            toast.success(t('message_profile_saved'));
            fetchData();
        } catch (errors) {
            for (let err of errors.response.data.errors) {
                toast.error(t(err.msg));
            }
        }

        return false;
    };

    const toBoolean = (value) => {
        switch (value) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return value;
        }
    };

    const loadServices = async () => {
        try {
            const serviceResponse = await axios.get(`/api/categories`, {
                headers: {'x-auth-token': auth.token},
            });

            let serviceCollection = [];
            serviceResponse.data.forEach((serviceCategory) => {
                serviceCollection.push({category: serviceCategory.name,});
                serviceCollection.push(...serviceCategory.services.map((service) => {
                    return {key: service._id, value: service.name};
                }));
            });

            setServices(serviceCollection);
        } catch(e) {
            console.log(e);
        }
    };

    /**
     * QUERIES
     */
    const fetchData = async () => {
        try {
            const responseRaw = await axios.get(`/api/users/${hostId}/profile?pending=1`, {
                headers: {'x-auth-token': auth.token},
            });

            const responseUserRaw = await axios.get(`/api/users/${hostId}?pending=1`, {
                headers: {'x-auth-token': auth.token},
            });

            let pendingData = false;
            let response = {};
            let userResponse = {};
            let responseVerified = {};
            let verifiedRequiredFields = 0;
            let filledRequiredFields = 0;
            let hasRejections = false;
            for (let key in responseRaw.data) {
                if (responseRaw.data[key] && responseRaw.data[key].value) {
                    response[key] = toBoolean(responseRaw.data[key].value);
                    responseVerified[key] = responseRaw.data[key];
                    pendingData = true;

                    if(responseVerified[key].required) {
                        filledRequiredFields++;
                    }

                    if (responseVerified[key].required && responseVerified[key].status === 1) {
                        verifiedRequiredFields++;
                    }

                    if (responseVerified[key].required && responseVerified[key].status === -1) {
                        hasRejections = true;
                    }
                }
                else {
                    response[key] = toBoolean(responseRaw.data[key]);
                }
            }

            for (let key in responseUserRaw.data) {
                if (responseUserRaw.data[key].value !== undefined) {
                    userResponse[key] = responseUserRaw.data[key].value;
                    responseVerified[key] = responseUserRaw.data[key];
                    pendingData = true;

                    if(responseVerified[key].required) {
                        filledRequiredFields++;
                    }

                    if (responseVerified[key].required && responseVerified[key].status === 1) {
                        verifiedRequiredFields++;
                    }

                    if (responseVerified[key].required && responseVerified[key].status === -1) {
                        hasRejections = true;
                    }
                }
                else {
                    userResponse[key] = responseUserRaw.data[key];
                }
            }

            setUser(userResponse);
            setVerified(responseVerified);

            setProfileState({...profileState, pending: pendingData, filledRequiredFields: filledRequiredFields === 3, verifiedRequiredFields: verifiedRequiredFields === 3, hasRejections: hasRejections});

            const imageResponse = await axios.get(`/api/hosts/${hostId}/avatar?base64=1&pending=1`);

            if (imageResponse.data !== '') {
                let images = [];
                for (const base64Index in imageResponse.data) {

                    images.push({
                        src: imageResponse.data[base64Index],
                        status: responseRaw.data.feature_image[base64Index] ? responseRaw.data.feature_image[base64Index].status : null,
                        id: responseRaw.data.feature_image[base64Index] ? responseRaw.data.feature_image[base64Index]._id : null,
                        order: responseRaw.data.feature_image[base64Index]
                                && responseRaw.data.feature_image[base64Index].meta
                                && responseRaw.data.feature_image[base64Index].meta.order !== undefined ? responseRaw.data.feature_image[base64Index].meta.order : 0,
                        video_id: responseRaw.data.feature_image[base64Index]
                        && responseRaw.data.feature_image[base64Index].meta
                        && responseRaw.data.feature_image[base64Index].meta.video_id !== undefined ? responseRaw.data.feature_image[base64Index].meta.video_id : '',
                    });
                }

                images = images.sort((a, b) => { return  a.order - b.order});

                setState({...state, ...response, avatar: images, images: images});
            }
            else {
                setState({...state, ...response});
            }

            if(response.description) {
                descriptionRef.current.set(response.description.model ? response.description.value : response.description);
            } else {
                setDescription('');
            }

            await loadServices();
        } catch (err) {
            console.log(err);
        }
    };

    const updateNotice = async (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');
        const fieldName = e.target.getAttribute('data-field');

        let newVerified = verified;

        newVerified[fieldName].notice = e.target.value;

        setVerified(newVerified);

        await axios.put(`/api/moderation/data/${id}/notice`, {message: e.target.value}, {
            headers: {
                'x-auth-token': auth.token
            },
        });
    };

    /**
     * SUBMIT
     */
    const submit = async (e) => {
        e.preventDefault();
        document.getElementById('btn_submit_profile').setAttribute('disabled', 'disabled');
        if (auth && (hostId !== auth.id && !isModerator(auth))) {
            return false;
        }

        if(!isModerator(auth)) {
            if (isHost(user) && stripHtml(description).length < parseInt(user && user.permissions ? user.permissions.descriptionMinLength : 0)) {
                toast.error(t('description_to_short_error').replace(`/\${minCharCount}/`, user && user.permissions ? user.permissions.descriptionMinLength : 0));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            } else if (stripHtml(description).length > parseInt(user && user.permissions ? user.permissions.descriptionMaxLength : 500)) {
                toast.error(t('description_to_long_error').replace(`/\${maxCharCount}/`, user && user.permissions ? user.permissions.descriptionMaxLength : 0));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            }

            if (isHost(user) && state.phone.length < 11) {
                toast.error(t('phone_to_short_error').replace(/\${minLength}/, 11));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            }

            // if (isHost(user) && user.google_email.length > 0 && user.google_email.indexOf('googlemail.com') === -1 && user.google_email.indexOf('gmail.com') === -1) {
            //     toast.error(t('google_mail_missing_address'));
            //     document.getElementById('btn_submit_profile').removeAttribute('disabled');
            //     return false;
            // }

            if (isHost(user) && state.gender.length === 0) {
                toast.error(t('gender_required_error'));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return;
            }

            if (isHost(user) && state.birth_year.length === 0) {
                toast.error(t('gender_required_error'));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return;
            }


            if (state.size !== 0 && state.size.length < 60 && state.size.length > 241) {
                toast.error(t('body_height_range_error').replace(/\${minSize}/, 60).replace(/\${maxSize}/, 241));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            }

            if (state.weight !== 0 && state.weight.length < 30 && state.weight.length > 501) {
                toast.error(t('body_weight_range_error').replace(/\${minWeight}/, 30).replace(/\${maxWeight}/, 500));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            }

            if (state.radius.length < 1 && state.size.length > 201) {
                toast.error(t('radius_range_error').replace(/\${minRadius}/, 1).replace(/\${maxRadius}/, 200));
                document.getElementById('btn_submit_profile').removeAttribute('disabled');
                return false;
            }
        }

        const raw = {...state};
        delete raw.avatar;
        delete raw.feature_image;
        const data = buildFormData(raw);

        if(isModerator(auth)) {
            data.append('uid', hostId);
        }

        const password = parentRef.current.getState();

        let userData = new FormData();
        userData.append('username', user.username);
        userData.append('email', user.email);
        userData.append('google_email', user.google_email);
        userData.append('password', password.password);
        userData.append('current_password', password.current_password);
        userData.append('password_confirm', password.password_confirm);

        try {
            let response = await axios.put(`/api/users/${isModerator(auth) && auth.id !== hostId ? hostId :  auth.id}/profile`, data, {
                headers: {
                    'x-auth-token': auth.token,
                    'Content-Type': 'multipart/form-data',
                },
            });

            await axios.put(`/api/users/${isModerator(auth) && auth.id !== hostId ? hostId :  auth.id}`, userData, {headers: {'x-auth-token': auth.token}});

            if (response.data.hasImage) {
                delete auth.redirectTo;
                localStorage.setItem('auth', JSON.stringify(auth));
                setAuth(auth);
            }

            toast.success(t('message_profile_saved'));
            window.setTimeout(() => {
                if(document.getElementById('btn_submit_profile')) {
                    document.getElementById('btn_submit_profile').removeAttribute('disabled');
                }
            }, 1000);
        } catch (err) {
            handleErrors(err, setAuth, history);

            document.getElementById('btn_submit_profile').removeAttribute('disabled');
        }

        return false;
    };

    let birthYear = [];

    const fromYears = moment().add(-80, 'years').format('YYYY');
    const toYears = moment().add(-17, 'years').format('YYYY');
    for (let ii = fromYears; ii < toYears; ii++) {
        birthYear.push({key: ii.toString(), value: ii.toString()});
    }

    return (
        <JezContainer className={classes.maxWidth1170} padding style={{margin: '96px auto 32px auto'}}
                      header={(isModerator(auth) ? user.username + "'s " : (isHost(user) ? "MODEL'S" : "USER'S")) + t('profile_link_text')}>

            {isModerator(auth) && isHost(user) && (<Fragment>
                <ul style={{textAlign: 'center'}}>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18, color: '#b4ab6e'}}><Trans>profile_link_text</Trans></li>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}/work/time`}><Trans>work_time_link_text</Trans></Link></li>
                    <li style={{display: 'inline-block', padding: '0 10px', fontSize: 18}}><Link to={`/profile/${hostId}/bookings`}><Trans>view_headline_moderation_booking</Trans></Link></li>
                </ul>
            </Fragment>)}

            {auth && hostId === auth.id && isHost(user) && (<>
                {!profileState.filledRequiredFields && !profileState.hasRejections && (
                    <JezPageInfo type={'warning'}>
                        <Trans>thanks_for_registration</Trans>
                    </JezPageInfo>)}

                {profileState.pending && profileState.filledRequiredFields && !profileState.verifiedRequiredFields && (
                    <JezPageInfo type={'warning'}>
                        <Trans>required_fields_verified</Trans>
                    </JezPageInfo>)}

                {profileState.pending && profileState.hasRejections && (
                    <JezPageInfo type={'warning'}>
                        <Trans>required_fields_rejected</Trans>
                    </JezPageInfo>)}
            </>)}

            <form onSubmit={submit}>
                {user && user.redirectTo && user.redirectTo.includes('profile_edit') && (
                    <JezErrorMessage>
                        <Trans>profile_image_error_message_text</Trans>
                    </JezErrorMessage>
                )}
                {(isHost(user) || isModerator(user)) && (
                    <Fragment>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} lg={11}>
                                        <JezHeading center variant='profileform' icon={<BsImage />} className={classes.sectionImageHeadline}>
                                            <span className={classes.headlineWidthSub}>
                                                <Trans>profile_images_group_title</Trans>
                                                <span className={classes.subHeadline}>
                                                    ({stringFormat(t('profile_images_count'), [state.images.filter((item) => { return !item.deleted && !item.video_id; }).length, user && user.permissions ? user.permissions.maxPictures : 1])})
                                                </span>
                                            </span>
                                            {user && user.permissions && user.permissions.maxVideos ? (<Fragment>
                                                &nbsp;&amp;&nbsp;
                                                <span className={classes.headlineWidthSub}>
                                                    <Trans>profile_video_group_title</Trans>
                                                    <span className={classes.subHeadline}>
                                                        ({stringFormat(t('profile_images_count'), [state.images.filter((item) => { return !item.deleted && item.video_id; }).length, user && user.permissions ? user.permissions.maxVideos : 1])})
                                                    </span>
                                                </span>
                                            </Fragment>) : ''}
                                        </JezHeading>
                                        <JezImageUpload images={state.avatar} self={auth && hostId === auth.id} onChange={addImage} imageCount={user && user.permissions ? user.permissions.maxPictures : 1} videoCount={user && user.permissions ? user.permissions.maxVideos : 1}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <JezProfilePersonalData user={user} data={state} verified={verified} onChange={change} checkboxHandler={checkboxHandler} onChangeUser={changeUser}/>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={11}>
                                {auth && ((hostId === auth.id && isHost(user)) || hostId !== auth.id) && (
                                    <JezProfileBodyProperties data={state} verified={verified} onChange={change}/>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <JezProfileLocation user={user} data={state} onChange={change} />
                    </Grid>
                </Grid>

                {(isHost(user) || isCustomer(user) || isModerator(user)) && (
                    <>
                <Grid container spacing={1}>
                    <Grid item xs={isCustomer(user) || isModerator(user) ? 12 : 6} className={classes.pendingDataContainer}>
                        <JezHeading center variant='profileform' icon={<BsTextCenter />} className={classes.sectionHeadline}>
                            <Trans>profile_description_group_title</Trans>
                        </JezHeading>
                        <div style={{position: 'relative'}}>
                        <div className={classes.iconContainerExtra}>
                            {auth && hostId !== auth.id && verified.description && (<span title={t('Field notice')}><ChatIcon aria-describedby={id} onClick={handleClick} data-field={'description'} data-notice={verified.description.notice} data-id={verified.description._id} className={classes.statusIcon}/></span>)}
                            {auth && hostId === auth.id && !verified.description && (<span title={t('Field is moderated')}><RateReviewIcon className={classes.statusIcon}/></span>)}
                            {auth && hostId === auth.id && verified.description && verified.description.status === 0 && (<span title={t('Field content is in review')}><TimerSharpIcon className={classes.statusIcon}/></span>)}
                            {auth && hostId === auth.id && verified.description && verified.description.status === 1 && (<span title={t('Accepted')}><DoneIcon className={classes.statusIcon}/></span>)}
                            {auth && hostId === auth.id && verified.description && verified.description.status === -1 && (<span title={t('Rejected')}><ThumbDownIcon className={classes.statusIcon}/></span>)}
                            {auth && hostId !== auth.id && verified.description && (
                                <CheckBoxIcon
                                    data-id={verified.description._id}
                                    className={
                                        classes.reviewAcceptIcon +
                                        ' data-accept ' +
                                        (verified.description.status === 1 ? classes.reviewChecked : '')
                                    }
                                    onClick={updateFieldState}
                                />
                            )}
                            {auth && hostId !== auth.id && verified.description && (
                                <IndeterminateCheckBoxIcon
                                    data-id={verified.description._id}
                                    className={
                                        classes.reviewRejectIcon +
                                        ' ' +
                                        (verified.description.status === -1 ? classes.reviewChecked : '')
                                    }
                                    onClick={updateFieldState}
                                />
                            )}
                        </div>

                        <JezTextEditor
                            placeholder={t('description_edit_placeholder').replace('{min}', user.permissions.descriptionMinLength)}
                            required
                            id='description'
                            onChange={(e) => changeDescription(e.target.name, e.target.value)}
                            name='description'
                            data={description}
                            setState={(val) => {
                                if (stripHtml(val).length > parseInt(user && user.permissions ? user.permissions.descriptionMaxLength : 500)) {
                                    if (!document.getElementById('description-letter-counter').classList.contains(`${classes.descriptionError}`)) {
                                        document.getElementById('description-letter-counter').classList.add(`${classes.descriptionError}`);
                                    }
                                }
                                else {
                                    document.getElementById('description-letter-counter').classList.remove(`${classes.descriptionError}`)
                                }
                                changeDescription('description', val);
                            }}
                            state={description}
                        />
                        <span id="description-letter-counter" className={classes.chEditorLength}>{stripHtml(description).length}/{user && user.permissions ? user.permissions.descriptionMaxLength : 500}</span>
                        {auth && hostId === auth.id && verified.description && verified.description.notice.length > 0 && (verified.description.status === 0 || verified.description.status === -1) && (<div className={classes.moderatorMessage}><strong>Moderator Message: </strong>{verified.description.notice}</div>)}
                        </div>
                        </Grid>
                    {isHost(user) && (
                        <Grid item xs={6}>
                            <JezHeading center variant='profileform' icon={<FaRegMoneyBillAlt/>} className={classes.sectionHeadline}>
                                <Trans>profile_service_prices</Trans>
                            </JezHeading>
                            <JezTextArea onChange={change} placeholder={t('profile_prices_placeholder')} name={'prices'}>{state.prices}</JezTextArea>
                        </Grid>
                    )}
                </Grid>
                </>)}

                {isHost(user) && (
                    <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={1}>
                                        <Grid item xs={12} lg={11}>
                                            <JezProfileMeetPoint data={state} verified={verified} onChange={changeMeet} onChangeLocation={changeMeetLocation}/>
                                        </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <JezHeading center variant='profileform' icon={<AiFillHeart/>} className={classes.sectionHeadline}>
                                    <Trans>profile_service_group_title</Trans>
                                </JezHeading>
                                <JezSelect
                                    className={'profileform'}
                                    helperText={t('public_services_help')}
                                    id='services'
                                    label={t("public_services_label")}
                                    name='public_services'
                                    onChange={(e) => change(e)}
                                    multiple
                                    options={services.filter((item) => { return !item.key || !(state.hidden_services.includes(item.key) || state.unwanted_services.includes(item.key)); })}
                                    value={state.public_services}
                                />
                                <JezSelect
                                    className={'profileform'}
                                    helperText={t('hidden_services_help')}
                                    id='services-hidden'
                                    label={t("hidden_services_label")}
                                    name='hidden_services'
                                    multiple
                                    onChange={(e) => change(e)}
                                    options={services.filter((item) => { return !item.key || !(state.public_services.includes(item.key) || state.unwanted_services.includes(item.key)); })}
                                    value={state.hidden_services}
                                />
                                <JezSelect
                                    className={'profileform'}
                                    helperText={t('unwanted_services_help')}
                                    id='services'
                                    label={t("unwanted_services_label")}
                                    multiple
                                    name='unwanted_services'
                                    onChange={(e) => change(e)}
                                    options={services.filter((item) => { return !item.key || !(state.public_services.includes(item.key) || state.hidden_services.includes(item.key)); })}
                                    placeholder=''
                                    value={state.unwanted_services}
                                />
                            </Grid>
                    </Grid>
                )}

                {auth && (hostId === auth.id || isModerator(auth) ) && (
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <JezHeading center variant='profileform' icon={<VpnKeyOutlinedIcon/>} className={classes.sectionHeadline}>
                                    <Trans>profile_password_group_title</Trans>
                                </JezHeading>
                                <PasswordForm
                                    current_password={!isModerator(auth) || hostId === auth.id}
                                    generate_password
                                    // once_password
                                    min_length={isModerator(auth) ? 0 : (config && config.password_security && config.password_security.minLength ? config.password_security.minLength : 10)}
                                    min_lowercase_count={isModerator(auth) ? 0 : (config && config.password_security && config.password_security.minLowercaseCount ? config.password_security.minLowercaseCount : 1)}
                                    min_uppercase_count={isModerator(auth) ? 0 : (config && config.password_security && config.password_security.minUppercaseCount ? config.password_security.minUppercaseCount : 1)}
                                    min_numbers_count={isModerator(auth) ? 0 : (config && config.password_security && config.password_security.minNumbersCount ? config.password_security.minNumbersCount : 1)}
                                    min_specialchars_count={isModerator(auth) ? 0 : (config && config.password_security && config.password_security.minSpecialcharsCount ? config.password_security.minSpecialcharsCount : 1)}
                                    use_forbiddenchars={config && config.password_security && config.password_security.forbiddenchars ? config.password_security.forbiddenchars : ' "\''}
                                    parentRef={parentRef}
                                    subState={{
                                        password: '',
                                        password_confirm: '',
                                        // once_password: false,
                                        showPassword: false,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>)}

                {auth && (hostId === auth.id || isModerator(auth)) && (
                    <Grid item xs={12}>
                        <JezCenter>
                            <JezButton type='submit' id={'btn_submit_profile'} margin center>
                                <Trans>save_btn_text</Trans>
                            </JezButton>
                        </JezCenter>
                    </Grid>)}
            </form>
            <JezzPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <h3 className={classes.popoverHeadline}><Trans>Notice</Trans></h3>
                <textarea defaultValue={moderated.notice} className={classes.popoverTextArea} data-field={moderated.field} data-id={moderated.id} onKeyUp={updateNotice} />
                <button onClick={handleClose} className={`btn primary`}><Trans>btn_save</Trans></button>
            </JezzPopover>
        </JezContainer>
    );
};

export default ProfileForm;
