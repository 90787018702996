import React from 'react';
import JezToTop from "../../components/toTopButton/toTopButton";
import SliderSection from "./sections/sliderSection";
import HostSection from "./sections/hostSection";
import ServiceSection from "./sections/serviceSection";
import ContactSection from "./sections/contactSection";

const Homepage = () => {
    return (
        <div id={'top'}>
            <SliderSection />
            <ServiceSection />
            <HostSection />
            <ContactSection />
            <JezToTop/>
        </div>
    );
};

export default Homepage;
