import React, {useContext, useEffect, useState} from 'react';
import JezContainer from "../../components/container/container";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import JezTable from "../../components/table/table";
import AuthContext from "../../context/auth";
import {useJezios} from "../../util/jezios";
import {Link} from "react-router-dom";
import moment from "moment";
import {isModerator} from "../../util/roles";

const useStyles = makeStyles((theme) => ({
    link: {
        color: '#b4ab6e',
        padding: '5px 10px',
        borderRadius: 4,
        border: '1px solid #b4ab6e',
        background: 'rgba(0,0,0,0.5)',
        '&:hover': {
            background: '#b4ab6e',
            color: '#fff'
        }
    },
    actionContainer: {
        textAlign: 'right',
        margin: '0 0 15px 0'
    },
    btn: {
        background: '#b4ab6e',
        padding: '5px 10px',
        borderRadius: 4,
        '&:hover': {
            background: '#716b44',
            color: '#fff'
        }
    }
}));

const JobList = ({history}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const [rows, setRows] = useState([]);

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    if (!auth || (auth && !isModerator(auth))) {
        history.push('/');
    }


    const request = useJezios(auth, t);

    const statusList = [
        t('private_label'),
        t('published_label'),
        t('published_at_label')
    ];

    const columns = [
        {name: 'name', title: t('table_name'), dataCellOptions: {component: 'th'}},
        {name: 'status', title: t('table_status'), render: (item) => { return statusList[item.publish] }},
        {name: 'publish_at', title: t('table_publish_at'), render: (item) => { const mom = moment(item.release ? item.release : item.createdAt); return mom.format('DD.MM.YYYY') }},
        {name: 'created_at', title: t('table_created_at'), render: (item) => { const mom = moment(item.createdAt); return mom.format('DD.MM.YYYY')}},
        {name: 'action', title: t('table_action'), dataCellOptions: {align: 'right'}, options: {align: 'right'}, render: (item) => { return (<Link className={classes.link} to={`/moderation/job/${item._id}`}><Trans>jobs_edit</Trans></Link> ); }}
    ];

    const fetch = async () => {
        const response = await request.get('/jobs?all=1');

        if(response) {
            setRows(response.data.data);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <JezContainer maxWidth={'lg'} padding style={{margin: '96px auto 32px auto'}} header={t('job_list')}>
            <div className={classes.actionContainer}>
                <Link className={classes.btn} to={'/moderation/job/create'}><Trans>create_jobs</Trans></Link>
            </div>
            <JezTable rows={rows} columns={columns}/>
        </JezContainer>
    );
};

export default JobList;