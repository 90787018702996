import PropTypes from "prop-types";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

import mapImage from "../../assets/img/map.jpg";

import "./map.scss";
import {Trans, useTranslation} from "react-i18next";
import GdprPermission from "../gdprPermission/gdprPermission";
import {GoLinkExternal} from "react-icons/all";

const Map = ({api, size, center, zoom, children, address, ...args}) => {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: api
    });

    /**
     * Context
     */
    const {t} = useTranslation();

    /**
     * States
     *
     */
    const [map, setMap] = useState(null);
    const [accepted, setAccepted] = useState(false);

    /**
     * Hooks
     */
    useEffect(() => {
        setSettingsOnReady(map, center, zoom);
    }, [map, center, zoom]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
            setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, []);

    const setSettingsOnReady = (_map, _center, _zoom) => {
        if(_map && _map.renderingType !== 'UNINITIALIZED') {
            _map.setCenter(_center);
            _map.setZoom(_zoom);
        } else {
            window.setTimeout(setSettingsOnReady, 250, _map, _center, _zoom);
        }
    }

    return (<div className={'map-container'}>
        {!accepted && (<div className={'placeholder-map'} style={{background: `url(${mapImage})`, height: `${size.height}px`, width: `${size.width}px`}}>
            <GdprPermission
                context={'Google'}
                type={'Map'}
                target={<div className={'enabling-map-check'} onClick={() => setAccepted(true)}><Trans>message_load_google_maps</Trans></div>}
            />
        </div>)}
        {isLoaded && accepted ? (<GoogleMap mapContainerStyle={{
                width:`${size.width}px`,
                height: `${size.height}px`
            }}
                                   center={center}
                                   zoom={zoom}
                                   onLoad={onLoad}
                                   onUnmount={onUnmount} >
                <Marker position={center} />
                {children}
            </GoogleMap>) : <Fragment />}
        {address && (<a className={'external-map-link'} title={t('label_title_map_route')} href={`https://www.google.de/maps/dir//${address}/@${center.lat},${center.lng},17z/`} target={'_blank'}><GoLinkExternal /><span><Trans>link_label_route</Trans></span></a>)}
    </div>);
}

Map.propTypes = {
    center: PropTypes.object.isRequired,
    api: PropTypes.string.isRequired,
    address: PropTypes.string
};

Map.defaultProps = {
    size: {width: 400, height: 225},
    zoom: 8
};

export default Map;