import React, {useState, useEffect, useContext, Fragment} from "react";
import JezHeading from "../components/heading/heading";
import JezContainer from "../components/container/container";
import JezSpacer from "../components/spacer/spacer";
import HouseIcon from '@material-ui/icons/House';
import {Grid, makeStyles} from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import {Trans, useTranslation} from "react-i18next";
import {randomId} from "../util/random";
import {useParams} from 'react-router-dom';
import { useJezios } from "../util/jezios"
import AuthContext from '../context/auth';
import JezButton from "../components/button/button";
import JezHostGrid from "../components/hostgrid/hostgrid";
import PersonIcon from '@material-ui/icons/Person';
import SubjectIcon from '@material-ui/icons/Subject';
import JezGallery from "../components/gallery/gallery";
import ModifyAblePageContent from "../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../components/contentContainer/contentContainer";


const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: '50px',
    },
    business_days: {
        listStyleType: 'none',
        padding: '0'
    },
    business_days_item_label: {
        display: 'inline-block',
        width: '80px'
    },
    editLocationButton: {
        width: 20,
        height: 20,
        position: 'absolute',
        right: 5,
        top: 5,
        background: '#b4ab6e',
        borderRadius: 3,
        color: '#fff',
        textAlign: 'center',
        lineHeight: '24px',
        cursor: 'pointer',

        '&:hover': {
            color: '#fff',
        }
    }
}));

const Location = ({ history }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    const {locationId} = useParams();
    const jezios = useJezios(auth, t);

    /**
     * STATES
     */
    const [location, setLocation] = useState({address: {}, business_hours: [], maps_url: ''});
    const [images, setImages] = useState([]);
    const [locationSystemId, setLocationSystemId] = useState(false);

    const formatDay = (number) => {
        switch(number) {
            case 0:
                return t('day_sunday');
            case 1:
                return t('day_monday');
            case 2:
                return t('day_tuesday');
            case 3:
                return t('day_wednesday');
            case 4:
                return t('day_thursday');
            case 5:
                return t('day_friday');
            case 6:
                return t('day_saturday');
            case 10:
                return t('day_daily');
            default:
                return '';
        }
    };

    const countryFormat = (iso) => {
        switch(iso) {
            case 'at':
                return t('country_austria');
            case 'de':
                return t('country_germany');
            default:
                return '';
        }
    };

    const initCall = () => {
        if(document.getElementsByClassName('initiate-call').length > 0) {
            document.getElementsByClassName('initiate-call')[0].click();
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // document.getElementById('location_contact').onclick = () => {
        //     if(document.getElementsByClassName('initiate-call').length > 0) {
        //         document.getElementsByClassName('initiate-call')[0].click();
        //     }
        // };

        window.setInterval(() => {
            if(document.getElementById('location_contact')) {
                if(document.getElementsByClassName('initiate-call').length > 0) {
                    document.getElementById('location_contact').removeAttribute('disabled');
                } else {
                    document.getElementById('location_contact').setAttribute('disabled', 'disabled');
                }
            }
        }, 1000);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const fetchData = async () => {
        const response = await jezios.get(`locations/${locationId}`);

        setLocationSystemId(response.data._id);

        let from = [];
        let to = [];

        for(let ii = 0; ii < response.data.business_hours.length; ii++) {
            from.push(response.data.business_hours[ii].from);
            to.push(response.data.business_hours[ii].to);
        }

        from = from.filter(onlyUnique);
        to = to.filter(onlyUnique);

        let data = {...response.data};

        if(from.length === 1 && to.length === 1) {
            data.business_hours = [{from: from[0], to: to[0], day: 10}]
        }

        setLocation(data);

        let list = [];
        for (const item of response.data.images) {
            list.push({
                src: item.src.media,
                thumbnail: item.src.media,
                videoId: item.src.video,
                thumbnailWidth: 342,
                thumbnailHeight: 260,
            });
        }

        setImages(list);
    };
    function getLocation() {
        if (location.maps_url != "") {
            return location.maps_url
        } else {
            return `https://www.google.com/maps/place/${location.address.street},+${location.address.zip}+${location.address.city},+${location.address.country}`;
        }
    }

    // if(!auth) {
    //     history.push('/');
    //     return ("<div />");
    // }

    return (
        <>
        <div style={{margin: '96px 0 0 0'}} />
        <JezContainer className={'maxWidth1170'} padding margin>
            <JezHeading center variant='h2'>
                {location.name}
            </JezHeading>
            <JezHeading center variant='h4'>
                <Trans>location_sub_title</Trans>
            </JezHeading>
            <JezSpacer icon={<HouseIcon/>}/>
            <Grid container spacing={1} className={classes.margin}>
                <Grid item xs={12} sm={12} md={location.extended ? 8 : 12}>
                    <JezGallery images={images}/>
                </Grid>
                {location.extended && (
                <Grid item xs={12} sm={12} md={4}>
                    <ul className={'listStyleNone'}>
                        <li>
                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <span className={'strong'}><Trans>location_label</Trans>:</span>
                                </Grid>
                                <Grid item sm={8}>
                                    <span className={'text'}>{location.name}</span>
                                </Grid>
                            </Grid>
                        </li>
                        <li>
                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <span className={'strong'}><Trans>phone_label</Trans>:</span>
                                </Grid>
                                <Grid item sm={8}>
                                    <span className={'text'}>{location.phone}</span>
                                </Grid>
                            </Grid>
                        </li>
                        <li>
                            <Grid container spacing={1}>
                                <Grid item sm={4} lg={4}>
                                    <span className={'strong'}><Trans>address_label</Trans>:</span>
                                </Grid>
                                <Grid item sm={8} lg={8}>
                                    <span className={`text`}>{location.address.street}</span>&nbsp;<span className={`text`}>{location.address.number}</span><br />
                                    <span className={'text'}>{location.address.zip}</span>&nbsp;<span className={'text'}>{location.address.city}</span><br />
                                    <span className={'text'}>{countryFormat(location.address.country)}
                                        <p>
                                    <a href= {getLocation()}
                                       target={"_blank"} className={'mapButton'}>
                                        Karte
                                    </a>
                                            </p>
                                        </span>
                                </Grid>
                            </Grid>
                        </li>
                        <li>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <span className={'strong'}><Trans>business_hours_label</Trans>:</span>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <ul className={classes.business_days}>
                                        {location.business_hours.map((item) => (
                                            <li key={randomId()}>
                                                <span className={`text ${classes.business_days_item_label}`}>{formatDay(item.day)}:</span>&nbsp;
                                                <span className={'text'}>{item.from}&nbsp;<Trans>time_clock</Trans>&nbsp;-&nbsp;</span>
                                                <span className={'text'}>{item.to}&nbsp;<Trans>time_clock</Trans></span>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            </Grid>
                        </li>
                    </ul>
                    <div className={'button-list'}>
                        <JezButton onClick={initCall} id={'location_contact'} margin fullwidth><Trans>make_an_appointment</Trans></JezButton>
                    </div>
                </Grid>)}
            </Grid>

            {location.extended && (<Fragment>
            <JezHeading center variant='h4'>
                <Trans>description_label</Trans>
            </JezHeading>
            <JezSpacer icon={<SubjectIcon/>}/>
            </Fragment>)}
            <div className={'text'} dangerouslySetInnerHTML={{__html: location.description}}/>
            <br />
            <br />

            {locationSystemId && (location.extended || location.slug === 'escort') && (<Fragment>
                <JezHeading center variant='h4'>
                    {location.extended && (
                        <Trans>our_girls_on_place</Trans>
                    )}
                    {location.slug === 'escort' && (
                        <Trans>our_girls_for_escort</Trans>
                    )}
                </JezHeading>
                <JezSpacer icon={<PersonIcon/>}/>
                <ContentContainer><ModifyAblePageContent context={'content-before-location-hosts'} /></ContentContainer>
                <JezHostGrid filter={{places: [locationSystemId]}} searchAble={true}/>
                <ContentContainer><ModifyAblePageContent context={'content-after-location-hosts'} /></ContentContainer>
            </Fragment>)}
            {/*<hr></hr>*/}
            {/*<h3><Trans>360_display</Trans></h3>*/}
            {/*<Grid container spacing={3}>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/DgAWmf/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/GLxTpg/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/c4NTxf/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/hwApsE/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/3F6iMR/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/RC3u2x/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/6wT6Hw/'}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={12} lg={12}>*/}
            {/*    <Player source={'https://360player.io/p/NA5Wxu/'}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </JezContainer>
            </>
    );
};

export default Location;