import JezHeading from "../../components/heading/heading";
import React, {useContext, Fragment, useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import axios from "axios";
import AuthContext from '../../context/auth';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import JezContainer from "../../components/container/container";
import {Grid} from "@material-ui/core";
import {useJezios} from "../../util/jezios";
import JezCheckbox from "../../components/checkbox/checkbox";
import {randomId} from "../../util/random";
import JezButton from "../../components/button/button";
import JezTable from "../../components/table/table";
import {toast} from 'react-toastify';
import JezModal from "../../components/modal/modal";
import {AiOutlineEye, BsCardImage} from 'react-icons/all';
import InfoBox from "../../components/infoBox/infoBox";
import {isModerator} from "../../util/roles";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: '48px auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    container: {
        width: '100%'
    },
    table: {
        color: '#fff'
    },
    openModalIcon: {
        color: '#fff',
        fontSize: 18,
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
    userName: {
        margin: '16px 0'
    },
    button: {
        background: '#807154',
        border: '1px solid #8071544 !important',
        color: '#fff',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer',
        opacity: 0.8,
        transform: 'scale(0.7)',
        transition:'all 0.25s linear',
        '&:hover': {
            opacity: 1,
            transform: 'scale(1)',
        }
    },
    buttonActive: {
        background: '#b4ab6e',
        border: '1px solid #b4ab6e',
        color: '#fff',
        margin: '5px',
        padding: '2px 3px;',
        cursor: 'pointer',
    },
    locationList: {
        padding: 0,
    },
    locationLabel: {
        color: '#fff'
    },
    contentPreview: {
        maxWidth: 250,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block'
    },
    previewImage: {
        width: '100%'
    },
    imageIcon: {
        fontSize: 25,
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    }
}));

const DataModerationForm = ({history, match: {params}}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if (!auth || (auth && !isModerator(auth))) {
        history.push('/');
    }

    const jezios = useJezios(auth, t);

    const { uid } = useParams();

    /**
     * STATES
     */
    const [rows, setRows] = useState([]);
    const [user, setUser] = useState({username: '', google_email: ''});
    const [locations, setLocations] = useState([]);
    const [profile, setProfile] = useState({});
    const [apiAuthenticated, setApiAuthenticated] = useState({google: false});

    const changeMultiValue = (e) => {
        let update = {
            ...profile
        };
        if(!update[e.target.name] instanceof Array) {
            update[e.target.name] = [];
        }
        if(e.target.checked) {
            update[e.target.name].push(e.target.value);
        }
        else {
            let index = update[e.target.name].indexOf(e.target.value);
            if (index >= 0) {
                update[e.target.name].splice(index, 1);
            }
        }
        setProfile(update);
    };

    const changeCalender = (e) => {
        setProfile({...profile, [e.target.name]: e.target.checked});
    }

    const fetchData = async () => {
        try {
            let responseUser = await axios.get(`/api/users/${uid}`, {
                headers: {'x-auth-token': auth.token},
            });

            setUser(responseUser.data);

            let response = await axios.get(`/api/moderation/data/${uid}`, {
                headers: {'x-auth-token': auth.token},
            });

            response.data = response.data.map((item, index) => {
                return {...item, id: index, user: item.user.username}
            });

            response.data.sort((item1, item2) => {
                return item1.field < item2.field;
            });

            setRows(response.data);

            const locationResponse = await jezios.get('locations');
            setLocations(locationResponse.data);

            const profileResponse = await jezios.get(`users/${uid}/profile?pending=1`);
            setProfile(profileResponse.data);

        } catch (err) {
            console.log(err);
        }
    };

    const updateRowState = async (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');

        try {
            await axios.put(`/api/moderation/data/${id}`, {status: e.target.classList.contains('data-accept') ? 'verified' : 'denied'}, {
                headers: {'x-auth-token': auth.token},
            });

            fetchData();
        } catch (err) {
            console.log(err);
        }

        return false;
    };

    const submit = async (e) => {
        e.preventDefault();

        let data = {uid: uid, calender_enabled: profile.calender_enabled};

        data.places = JSON.stringify(profile.places instanceof Array ? profile.places : [profile.places]);

        await jezios.put(`users/${uid}/profile`, data);
        toast.success(t('user_data_saved'));

        return false;
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchData();
        checkGoogleAPI();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkGoogleAPI = async () => {
        try {
            const response = await axios.get(`/api/system/google/is/auth`, {
                headers: {'x-auth-token': auth.token},
            });

            setApiAuthenticated({...apiAuthenticated, google: response.data.auth});
        } catch(err) {
            console.log(err);
        }
    }

    const columns = [
        {name: 'field', title: t('moderation_table_column_field'), dataCellOptions: {component: 'th'}},
        {name: 'value', title: t('moderation_table_column_value'), render: (item) => {
            const value = item.value;
                switch(value) {
                    case 'true':
                        return 'Enabled';
                    case 'false':
                        return 'Disabled';
                    default:
                        if(item.field === 'feature_image') {
                            return (
                                <Fragment>
                                    <JezModal icon={<BsCardImage className={classes.imageIcon}/>}>
                                        <img className={classes.previewImage} src={`/api/hosts/${uid}/avatar/single/${value}`} />
                                    </JezModal>
                                </Fragment>);
                        } else if(value.length > 100) {
                            return (
                                <Fragment>
                                    <span className={classes.contentPreview}>{value}</span>
                                    <JezModal icon={<AiOutlineEye className={classes.openModalIcon}/>}>
                                        <div className="content" dangerouslySetInnerHTML={{__html: value}} />
                                    </JezModal>
                                </Fragment>);
                        }
                        return <span className={classes.contentPreview}>{value}</span> //value.length > 100 ? value.substr(0, 100) : value;
                }
             }},
        {name: 'date', title: t('moderation_table_column_date'), render: (item) => { const mom = moment(item.created); return mom.format('DD.MM.YYYY'); }},
        {name: 'time', title: t('moderation_table_column_time'), render: (item) => { const mom = moment(item.created); return mom.format('HH:mm:ss'); }},
        {name: 'required', title: t('moderation_table_column_required'), render: (item) => { return item.required ? <strong><Trans>button_yes</Trans></strong> : <Trans>button_no</Trans>; }},
        {name: 'action', title: t('table_action'), dataCellOptions: {align: 'right'}, options: {align: 'right'}, render: (item) => {
                return (<div>
                    <button className={item.status === -1 ? classes.buttonActive : classes.button} data-id={item._id} onClick={updateRowState}><Trans>button_reject</Trans></button>
                    <button data-id={item._id} className={(item.status === 1 ? classes.buttonActive : classes.button) + ' data-accept'} onClick={updateRowState}><Trans>button_accept</Trans></button>
                </div>)
            }
        }
    ];

    return (
        <>
        <div style={{margin: '96px 0 0 0'}} />
        <JezContainer padding className={classes.maxWidth1170} header={t('view_headline_moderation_data')}>
            <JezHeading className={classes.userName} center variant='h3'>
                {user.username}
            </JezHeading>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <JezHeading className={classes.userName} center variant='h5'>
                        <Trans>headline_locations</Trans>
                    </JezHeading>
                    <Grid container spacing={1}>
                        {locations && locations.map((item) => (
                            <Grid item xs={12} key={randomId()}>
                                <JezCheckbox checked={profile.places && profile.places.includes(item._id)} onChange={changeMultiValue} id={randomId()} name={'places'} label={item.name} value={item._id}/>
                            </Grid>))}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <JezHeading className={classes.userName} center variant='h5'>
                        <Trans>headline_calender</Trans>
                    </JezHeading>
                    {user.google_email && user.google_email.length > 0 && apiAuthenticated.google && (
                        <JezCheckbox checked={profile.calender_enabled} id={randomId()} onClick={changeCalender} name={'calender_enabled'} label={t('label_calender_enable')} value={1}/>
                    )}
                    {!user.google_email || user.google_email.length === 0 && (
                        <InfoBox><Trans>user_requires_google_mail_address</Trans></InfoBox>
                        )}
                    {!apiAuthenticated.google && (<InfoBox><Trans>require_access_to_google_api</Trans></InfoBox>)}
                </Grid>
            </Grid>

            <JezHeading className={classes.userName} center variant='h5'>
                <Trans>headline_moderated_fields</Trans>
            </JezHeading>
            <div className={classes.container}>
                <JezTable rows={rows} columns={columns}/>
            </div>
            <form onSubmit={submit}>
                <br />
                <JezButton><Trans>button_save</Trans></JezButton>
            </form>
        </JezContainer>
            </>
    )
};

export default DataModerationForm;