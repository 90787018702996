import React, {Fragment} from "react";
import JezHeading from "../components/heading/heading";
import {Trans} from "react-i18next";
import JezSpacer from "../components/spacer/spacer";
import mask from "../assets/img/mask_76x27x96.png";
import {Container, Grid, makeStyles} from "@material-ui/core";

import escort from "../assets/img/escort.jpg";
import wellness from "../assets/img/wellness.jpg"

const useStyles = makeStyles((theme) => ({
    splitter: {
        margin: '48px 0 0 0'
    },
    maxWidth1170: {
        maxWidth: '768px',
        margin: 'auto',
        textAlign: "left"
    },
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '30px 0 70px',
        position: 'relative'
    },
    data: {
        color: '#fff'
    },
    link: {
        color: '#b4ab6e',
        '&:hover': {
            color: '#716b44'
        }
    },
    licenceList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    }
}));

const Impress = () => {

    /**
     * CSS
     */
    const classes = useStyles();

    return (<Fragment>
        <Grid container spacing={1} id={'contact'} className={`${classes.padding} ${classes.black}`}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <JezHeading center variant='h2' className={classes.splitter}>
                            <Trans>header_impress</Trans>
                        </JezHeading>
                        <JezSpacer icon={<img src={mask} alt='mask'/>}/>
                    </Grid>
                </Grid>
                <Container component='div' className={classes.maxWidth1170}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>headline_label_managing_director</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Frau <br />
                                Adriana PONEA <br />
                                E-Mail: <a className={classes.link} href='mailto:info@jezzaber.com'>info@jezzaber.com</a>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>headline_label_address</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                IFMG Event- und Gastronomie GmbH<br />
                                Auststellungsstraße 50 / C / 2OG<br />
                                1020 Wien<br />
                                Österreich<br />
                                Tel.: <a className={classes.link} href="phone:+43125659540">+43 1 25659540</a><br />
                                E-Mail: <a className={classes.link} href='mailto:info@jezzaber.com'>info@jezzaber.com</a>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>headline_label_company_number</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Firmengericht Wien<br />
                                Firmennummer: 551783x<br/>
                                <a className={classes.link} target={'_blank'} href={'https://firmen.wko.at/ifmg-event--und-gastronomie-gmbh/wien/?firmaid=fce4a2a5-b33c-44a0-8d94-c296a0101896'} ><Trans>link_label_wko</Trans></a>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>headline_label_media</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                Alle Bilder und Videos auf JEzzABER.com sind Eigentum der IFMG Event- und Gastronomie GmbH oder sind von <a className={classes.link} target={'_blank'} href={'https://www.istockphoto.com/de'} ><Trans>iStock</Trans></a> lizensiert.<br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <JezHeading variant='h4'>
                                <Trans>headline_label_licence</Trans>
                            </JezHeading>
                            <div className={classes.data}>
                                <ul className={classes.licenceList}>
                                    <li><a className={classes.link} href={escort} target={'_blank'}>escort.jpg</a> lizenziert von <a className={classes.link} target={'_blank'} href={'https://www.istockphoto.com/de'} ><Trans>iStock</Trans></a></li>
                                    <li><a className={classes.link} href={wellness} target={'_blank'}>wellness.jpg</a> lizenziert von <a className={classes.link} target={'_blank'} href={'https://www.istockphoto.com/de'} ><Trans>iStock</Trans></a></li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    </Fragment>);
}

export default Impress;