import React, {Fragment, useEffect, useState} from 'react';
import JezHeading from "../../../components/heading/heading";
import {Trans, useTranslation} from "react-i18next";
import {Grid, makeStyles} from "@material-ui/core";
import JezSelect from "../../../components/select/select";
import {isHost} from "../../../util/roles";
import {FaMapMarkedAlt} from 'react-icons/all';
import JezTextEdit from "../../../components/textedit/textedit";
import Countries from "i18n-iso-countries";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const useStyles = makeStyles((theme) => ({
    sectionHeadline: {
        margin: '50px 0 15px 0 !important'
    },
    pendingDataContainer: {
        position: 'relative',
    },
    iconContainer: {
        position: 'absolute',
        right: '10px',
        top: '29px',
        color: '#888',
        zIndex: 10,
    },
    statusIcon: {
        margin: '0 0 0 5px',
        color: '#888',
        cursor: 'pointer',
        '&:hover': {
            color: '#b4ab6e'
        }
    },
}));

const JezProfileLocation = ({user, data, onChange}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * CONTEXT
     */
    const {t} = useTranslation();

    const [state, setState] = useState(null);

    const change = async (e) => {
        if(onChange) {
            await onChange(e);
        }
    };

    useEffect(() => {
        setState(data);
    }, [data]);

    const changeNumber = async (e) => {
        e.target.value = e.target.value.replace(/^0/, '').replace(/[^0-9+ ]+/, '').replace('  ', ' ');

        await change(e);
    };

    const countries = () => {
        let countries = Countries.getNames("de", {select: "all"});
        return Object.keys(countries).map(function(key) {
            return {
                key: key,
                value: countries[key]
            };
        })
    };

    if(!state) {
        return '';
    }

    return (
        <Fragment>
            <JezHeading center variant='profileform' icon={<FaMapMarkedAlt />} className={classes.sectionHeadline}>
                <Trans>profile_location_group_title</Trans>
            </JezHeading>
            <JezSelect
                label={t("nationality_label")}
                name='nationality'
                onChange={(e) => change(e)}
                id='nationality'
                placeholder=''
                value={state.nationality}
                options={[
                    {key: '', value: '', before: true},
                    ...[
                        {category: t('nationality_category_regions')},
                        {key: 'european', value: t('european')},
                        {key: 'scandinavian', value: t('scandinavian')},
                        {key: 'eastern european', value: t('eastern european')},
                        {key: 'western european', value: t('western european')},
                        {key: 'asian', value: t('asian')},
                        {key: 'latin', value: t('latin')},
                        {key: 'african', value: t('african')},
                        {category: t('nationality_category_countries')},
                    ],
                    ...countries(),
                ]}
                className={'profileform'}
            />
            <Grid container spacing={1}>
                {isHost(user) && (
                    <Grid item xs={12} sm={4} className={classes.pendingDataContainer}>
                        <JezSelect
                            label={t("meeting_point_label")}
                            name='location'
                            onChange={(e) => change(e)}
                            id='location'
                            placeholder=''
                            value={state.location}
                            options={[
                                {key: '', value: '', before: true},
                                {key: 'club_jezzaber', value: t('club_jezzaber')},
                                {key: 'club_studio_eroscenter', value: t('club_studio_eroscenter')},
                                {key: 'my_hotel', value: t('my_hotel')},
                                {key: 'at_your', value: t('at_your')},
                            ]}
                            className={'profileform'}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sm={isHost(user) ? 4 : 12}
                >

                    <JezTextEdit
                        label={t("city_label")}
                        name='city'
                        id='city'
                        onChange={(e) => change(e)}
                        placeholder={t("city_label")}
                        type='text'
                        className={'profileform'}
                        value={state.city}
                    />
                </Grid>

                {isHost(user) && state.radius !== undefined && (
                    <Grid item xs={12} sm={4} className={classes.pendingDataContainer}>
                        <div className={classes.iconContainer}>
                            <span className={classes.unit}>km</span>
                        </div>
                        <JezTextEdit
                            label={t("radius_label")}
                            name='radius'
                            id='radius'
                            onChange={(e) => changeNumber(e)}
                            placeholder={t("radius_label")}
                            type='number'
                            className={'profileform'}
                            value={state.radius}
                        />
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default JezProfileLocation;