import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {propTypes} from "react-slideshow-image/lib/props";
import Map from "../map/map";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "transparent",
        '& .tabs': {
            backgroundColor: "transparent",
        }
    },
    tabNavigation: {
        backgroundColor: "transparent",
        boxShadow: 'none',
        '& .MuiTabs-indicator' : {
            backgroundColor: '#b4ab6e'
        }
    },
    tab: {
        color: '#b4ab6e'
    }
}));

const  JezTab = ({children}) => {
    const classes = useStyles();

    /**
     * States
     */
    const [value, setValue] = React.useState(0);
    const [items, setItems] = useState([]);

    /**
     * Hooks
     */
    useEffect(() => {
        if(children) {
            setItems(children instanceof Array ? children : [children]);
        }
    }, [children]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" className={classes.tabNavigation}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {items.map((item, index) => (<Tab className={classes.tab} label={item.props.title} {...a11yProps(index)} />))}
                    {/*<Tab label="Item One" {...a11yProps(0)} />*/}
                    {/*<Tab label="Item Two" {...a11yProps(1)} />*/}
                    {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
                    {/*<Tab label="Item Four" {...a11yProps(3)} />*/}
                    {/*<Tab label="Item Five" {...a11yProps(4)} />*/}
                    {/*<Tab label="Item Six" {...a11yProps(5)} />*/}
                    {/*<Tab label="Item Seven" {...a11yProps(6)} />*/}
                </Tabs>
            </AppBar>
            {items.map((item, index) => (<TabPanel index={index} value={value}>{item}</TabPanel>))}
            {/*<TabPanel value={value} index={0}>*/}
            {/*    Item One*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={1}>*/}
            {/*    Item Two*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={2}>*/}
            {/*    Item Three*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={3}>*/}
            {/*    Item Four*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={4}>*/}
            {/*    Item Five*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={5}>*/}
            {/*    Item Six*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={6}>*/}
            {/*    Item Seven*/}
            {/*</TabPanel>*/}
        </div>
    );
}

JezTab.propTypes = {
};

JezTab.defaultProps = {
    children: [],
};

export default JezTab;
