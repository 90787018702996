import React, {useContext} from 'react';
import JezHeading from "../components/heading/heading";
import Homepage from './homepage/homepage';
import {Route, Switch} from 'react-router-dom';
import JezNavbar from '../components/navbar/navbar';
import Premium from './premium';
import Activation from './activation';
import RegistrationForm from '../forms/registration';
import LoginForm from '../forms/login';
import ModerationDataForm from '../forms/moderation/data';
import ModerationUserForm from '../forms/moderation/user';
import JezProfile from '../components/profile/profile';
import JezFooter from '../components/footer/footer';
import ProfileForm from "../forms/profile";
import HostList from "./hosts";
import AuthContext from '../context/auth';
import JezVideoCall from "../components/videoCall/videoCall";
import Location from "./location";
import LocationForm from "./../forms/locationForm";
import LocationList from "./locations";
import EscortDetails from "./escort";
import NewsForm from "../forms/moderation/newsForm";
import NewsList from "../forms/moderation/newsList";
import News from "./news";
import ModerationLocationList from "../forms/moderation/locationList"
import LegalAge from "../components/legalAge/legalAge";
import NewsDetails from "./newsDetails";
import JobList from "../forms/moderation/jobList";
import JobForm from "../forms/moderation/jobForm";
import Jobs from "./jobs";
import JobDetails from "./jobDetails";
import JezGoogleSettings from "../forms/system/google/settings";
import WorkingTimeForm from "../forms/workingTime";
import ModerationBookList from "./book/list";
import Impress from "./impress";
import Privacy from "./privacy";
import BookingListForm from "../forms/moderation/bookingList";

const Application = ({ history }) => {
	const { auth } = useContext(AuthContext);

	if(auth && auth.redirectTo && auth.redirectTo === 'profile_edit'){
		if (history.location.pathname !== '/profile') history.push('/profile');
	}

	return (
		<>
		<LegalAge/>
		<div className='wrap'>
			<JezNavbar title={<JezHeading variant={'portalName'}/>} />
			<Switch>
				<Route exact path='/locations' component={LocationList} />
				<Route path='/escort' component={EscortDetails} />
				<Route exact path='/hosts' component={HostList} />
				<Route exact path='/hosts/work/time' component={WorkingTimeForm} />
				<Route exact path='/premium' component={Premium} />
				<Route exact path='/member/:hostId' component={JezProfile} />
				<Route exact path='/host/:hostId/profile' component={JezProfile} />
				<Route exact path='/profile' component={ProfileForm} />
				<Route exact path='/profile/:hostId' component={ProfileForm} />
				<Route exact path='/profile/:hostId/work/time' component={WorkingTimeForm} />
				<Route exact path='/profile/:hostId/bookings' component={BookingListForm} />
				<Route exact path='/users/activate' component={Activation} />
				<Route exact path='/register/:type' component={RegistrationForm} />
				<Route exact path='/register' component={RegistrationForm} />
				<Route exact path='/login' component={LoginForm} />
				<Route exact path='/locations/:locationId' component={Location} />
				<Route exact path='/news' component={News} />
				<Route exact path='/news/:newsId' component={NewsDetails} />
				<Route exact path='/jobs' component={Jobs} />
				<Route exact path='/job/:jobId' component={JobDetails} />

				<Route exact path='/moderation/hosts/reservation' component={BookingListForm} />
				<Route exact path='/moderation/hosts/booking' component={ModerationBookList} />
				<Route exact path='/moderation/locations' component={ModerationLocationList} />
				<Route exact path='/moderation/locations/:locationId' component={LocationForm} />
				<Route exact path='/moderation/user/:uid' component={ModerationDataForm} />
				<Route exact path='/moderation/user' component={ModerationUserForm} />
				<Route exact path='/moderation/news' component={NewsList} />
				<Route exact path='/moderation/news/create' component={NewsForm} />
				<Route exact path='/moderation/news/:newsId' component={NewsForm} />
				<Route exact path='/moderation/jobs' component={JobList} />
				<Route exact path='/moderation/job/create' component={JobForm} />
				<Route exact path='/moderation/job/:jobId' component={JobForm} />

				<Route exact path='/system/google' component={JezGoogleSettings} />

				<Route path='/impressum' component={Impress} />
				<Route path='/datenschutz' component={Privacy} />

				<Route path='/' component={Homepage} />

			</Switch>
			{auth && (<JezVideoCall />)}
		</div>
		<JezFooter />
		</>
	);
};

export default Application;
