import React, {Fragment, useContext, useEffect, useState} from 'react';
import JezHeading from "../components/heading/heading";
import JezContainer from "../components/container/container";
import {Grid, makeStyles} from "@material-ui/core";
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useJezios} from "../util/jezios";
import AuthContext from "../context/auth";
import JezOpeningHours from "../sections/openingHours";
import JezTextEditor from "../components/texteditor/texteditor";
import JezTextEdit from "../components/textedit/textedit";
import JezImageUpload from "../components/imageUpload/imageUpload";
import JezAddress from "../sections/address";
import {buildFormData} from "../util/tools";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    headline: {
        margin: '0 0 15px 0'
    },
    imageHeadline: {
        marginBottom: 17
    },
    submit: {
        width: '100%',
        margin: '15px 0 0 0'
    },
    headlineDescription: {
        marginBottom: 32
    }

}));

export default function LocationForm({history}) {
    /**
     * CSS
     */
    const classes = useStyles();

    const [location, setLocation] = useState({phone: '', name: '', images: [], business_hours: [], address: {}, maps_url: ''});
    const [description, setDescription] = useState('');

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const request = useJezios(auth);

    const {t} = useTranslation();
    const {locationId} = useParams();

    const fetch = async () => {
        const response = await request.get(`locations/${locationId}`);

        setLocation(response.data);

        /**
         * NOTICE: if we add later other states the state must the last state that set.
         */
        setDescription(response.data.description);
    };

    const changeOpeningHours = (data) => {
        let update = {...location};
        update.business_hours[data.id].from = data.from;
        update.business_hours[data.id].to = data.to;
        setLocation(update);
    };

    const change = (e) => {
        if (e.target.name === 'description') {
            setDescription(e.target.value);
        }
        setLocation({...location, [e.target.name]: e.target.value});
    };

    const changeAddress = (data) => {
        setLocation({...location, address: data});
    };

    const changeNumber = (e) => {
        const value = e.target.value.replace(/^0/, '').replace(/[^0-9+ ]+/, '').replace('  ', ' ');
        setLocation({...location, [e.target.name]: value});
    };

    const addImage = (imageList) => {
        setLocation({...location, images: imageList});
    };

    const submit = async () => {
        const formData = buildFormData(location);

        const response = await request.put(`locations/${locationId}`, formData, {header: {'Content-Type': 'multipart/form-data'}});

        if (response) {
            toast.success(t('message_location_saved'));
        }
    };

    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // return (
    //     <JezContainer className={classes.maxWidth1170} padding style={{margin: '96px auto 32px auto'}} header={location.extended ? t('header_edit_location') : t('header_edit_escort')}>
    //         <Grid container spacing={1}>
    //             <Grid item xs={12} sm={12} lg={location.extended ? 5 : 12}>
    //                 <JezHeading center variant='profileform' className={classes.imageHeadline}>
    //                     <Trans>label_images</Trans>
    //                 </JezHeading>
    //                 <JezImageUpload images={location.images} self={true} onChange={addImage}/>
    //             </Grid>
    //         </Grid>
    //     </JezContainer>
    // );

    return (
        <Fragment>
            <JezContainer className={classes.maxWidth1170} padding style={{margin: '96px auto 32px auto'}}
                          header={location.extended ? t('header_edit_location') : t('header_edit_escort')}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={location.extended ? 5 : 12}>
                        <JezHeading center variant='profileform' className={classes.imageHeadline}>
                            <Trans>label_images_and_videos</Trans>
                        </JezHeading>
                        <JezImageUpload images={location.images} self={true} onChange={addImage}/>
                    </Grid>
                    {location.extended && (<Fragment>
                        <Grid item xs={12} sm={12} lg={1}/>
                        <Grid item xs={12} sm={12} lg={6}>
                            <JezHeading center variant='profileform'>
                                <Trans>location_data</Trans>
                            </JezHeading>
                            <JezTextEdit
                                label={t("name_label")}
                                defaultValue={location.name}
                                name='name'
                                onChange={(e) => change(e)}
                                id='name'
                                type='text'
                                placeholder={t("name_label")}
                                value={location.name}
                            />
                            <JezTextEdit
                                label={t("phone_label")}
                                defaultValue={location.phone}
                                name='phone'
                                onChange={(e) => changeNumber(e)}
                                id='phone'
                                type='text'
                                placeholder={t("phone_label")}
                                value={location.phone}
                            />
                            <JezHeading center variant='profileform' className={classes.headline}>
                                <Trans>business_hours_label</Trans>
                            </JezHeading>
                            <JezOpeningHours openingTimes={location.business_hours} onChange={changeOpeningHours}/>
                        </Grid>
                    </Fragment>)}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={location.extended ? 8 : 12}>
                        <JezHeading center variant='profileform'
                                    className={`${classes.headline} ${classes.headlineDescription}`}>
                            <Trans>label_description</Trans>
                        </JezHeading>
                        <JezTextEditor required id='description' onChange={(e) => change(e)} name='description'
                                       toolbar={['link']}
                                       data={description}
                                       setState={(val) => {
                                           setDescription(val);
                                           setLocation({...location, description: val});
                                       }}
                                       state={description}
                        />
                    </Grid>
                    {location.extended && (
                        <Grid item xs={12} sm={4}>
                            <JezHeading center variant='profileform' className={classes.headline}>
                                <Trans>address_label</Trans>
                            </JezHeading>
                            <JezAddress address={location.address} onChange={changeAddress}/>
                            <JezTextEdit
                                label={t("map_url")}
                                defaultValue={location.maps_url}
                                name='maps_url'
                                onChange={(e) => change(e)}
                                id='maps_url'
                                type='text'
                                placeholder={t("url_label")}
                                value={location.maps_url}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={0} sm={0} lg={5}>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2}>
                        <button onClick={submit} className={`btn primary ${classes.submit}`}><Trans>btn_save</Trans>
                        </button>
                    </Grid>
                </Grid>
            </JezContainer>
        </Fragment>
    );
}