const escapeStringRegexp = require('escape-string-regexp');

const Password = {
    t: null,

    setTranslator: function(t) {
        this.t = t;
    },

    passwordCharacters: {
        specials: '-<>,.;:_#*+~^!§$%&/()=?[]{}\\',     // not ° |²³£ because risk of confusion
        // important use '-' to the beginning of string, otherwise additionally chars can be found
        lowercase: 'abcdefghjkmnpqrstuvwxyzäöüß',      // not o,i,l because risk of confusion
        uppercase: 'ABCDEFGHIJKLMNPQRSTUVWXYZÄÖÜ',     // not O because risk of confusion
        numbers: '123456789',                          // not 0 because risk of confusion
        forbidden: 'oilO° |²³£"\'',                    // to to avoid confusion
    },

    generatePassword: function(minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2) {
        let counts = [];
        counts[0] = minSpecialcharsCount;
        counts[1] = minLowercaseCount;
        counts[2] = minUppercaseCount;
        counts[3] = minNumbersCount;

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        let password = "";
        while (password.length < minLength) {
            let type = getRandomInt(counts.length);
            if (counts[type] > 0 || counts.join("") === "0000") {
                password += this.passwordCharacters[Object.keys(this.passwordCharacters)[type]][Math.floor(
                    Math.random() * (Object.keys(this.passwordCharacters)[type].length))];
                if (counts[type] > 0) {
                    counts[type]--;
                }
            }
        }

        return password;
    },

    /**
     * check @p password meets the criteria given by next parameters.
     *
     * @param password
     * @param minLength if > 0 password must have at least @p minLength characters
     * @param minLowercaseCount if > 0 password must have at least @p minLowercaseCount lowercase characters
     * @param minUppercaseCount if > 0 password must have at least @p minUppercaseCount uppercase characters
     * @param minNumbersCount if > 0 password must have at least @p minNumbersCount numbers
     * @param minSpecialcharsCount if > 0 password must have at least @p minSpecialcharsCount special characters
     * @param maxLength if > 0 password must have maximum @p maxLength characters
     * @param forbiddenchars if not false password can not have @p forbiddenchars or 'oilO° |²³£' if @p forbiddenchars is true
     *
     * @returns {*[]} an array of errors or empty array if check was successfully
     */
    checkPasswordComplexity: function(password, minLength = 12, minLowercaseCount = 2, minUppercaseCount = 2, minNumbersCount = 2, minSpecialcharsCount = 2, maxLength = 4096, forbiddenchars = true) {
        let errors = [];
        let matches = '';
        let regExp = null;

        if (typeof forbiddenchars !== 'boolean') {
            this.passwordCharacters['forbidden']= forbiddenchars;
        }

        //@Deprecated not allowed characters
        // let allPasswordCharacters = escapeStringRegexp(this.passwordCharacters['specials']);
        // for (let c = 1; c < (Object.keys(this.passwordCharacters).length - 1); c++) {
        //     allPasswordCharacters += this.passwordCharacters[Object.keys(this.passwordCharacters)[c]];
        // }
        // let regExp = new RegExp('[^' + allPasswordCharacters + ']', 'g'); // 0-9a-zäöüßA-ZÄÖÜ <>|,.\-;:_#*+~^°!§$£%&\/()=?²³\[\]{}\\
        // let matches = password.match(regExp);
        // if (matches != null && matches.length > 0) {
        //     errors.push(
        //         this.t('password_js_forbidden_chars_found') + " '" + matches.join("") + "'."
        //     );
        // }

        if (maxLength > 0 && password.length > maxLength) {
            errors.push(
                this.t('password_js_max_length_reached')
                    + " " + this.t('password_js_allowed').replace(/\${allowed}/, maxLength)
                    + " " + this.t('password_js_found').replace(/\${found}/, password.length)
            );
        }

        if (minLength > 0 && password.length < minLength) {
            errors.push(
                this.t('password_js_min_length_reached')
                    + " " + this.t('password_js_minimum').replace(/\${min}/, minLength)
                    + " " + this.t('password_js_found').replace(/\${found}/, password.length)
            );
        }

        if (minLowercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['lowercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minLowercaseCount) {
                errors.push(
                    this.t('password_js_min_lowercase_count_reached')
                        + " " + this.t('password_js_minimum').replace(/\${min}/, minLowercaseCount)
                        + (matches != null && matches.length > 0 ? " " + this.t('password_js_found').replace(/\${found}/, matches.join(",")) : "")
                );
            }
        }

        if (minUppercaseCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['uppercase'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minUppercaseCount) {
                errors.push(
                    this.t('password_js_min_uppercase_count_reached')
                        + " " + this.t('password_js_minimum').replace(/\${min}/, minUppercaseCount)
                        + (matches != null && matches.length > 0 ? " " + this.t('password_js_found').replace(/\${found}/, matches.join(",")) : "")
                );
            }
        }

        if (minNumbersCount > 0) {
            regExp = new RegExp('[' + this.passwordCharacters['numbers'] + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minNumbersCount) {
                errors.push(
                    this.t('password_js_min_numbers_count_reached')
                        + " " + this.t('password_js_minimum').replace(/\${min}/, minNumbersCount)
                        + (matches != null && matches.length > 0 ? " " + this.t('password_js_found').replace(/\${found}/, matches.join(",")) : "")
                );
            }
        }

        if (minSpecialcharsCount > 0) {
            regExp = new RegExp('[' + escapeStringRegexp(this.passwordCharacters['specials']) + ']', 'g');
            matches = password.match(regExp);
            if (matches == null || matches.length < minSpecialcharsCount) {
                errors.push(
                    this.t('password_js_min_specialchars_count_reached')
                        + " " + this.t('password_js_minimum').replace(/\${min}/, minSpecialcharsCount)
                        + (matches != null && matches.length > 0 ? " " + this.t('password_js_found').replace(/\${found}/, matches.join(",")) : "")
                );
            }
        }
        return errors;
    },

    checkPasswordCompare: function(password1, password2) {
        if (password2.length === 0) {
            return '';
        }
        for (let c = 0; c < password1.length; c++) {
            if (password1[c] !== password2[c]) {
                return this.t('password_js_passwords_not_identical').replace(/\${charOffset}/, c + 1);
            }
        }
        return "";
    }
};

export default Password;