import React, {Fragment} from 'react';
import './dragAbleContainer.scss';

import MovablePopover from 'movable-popover-react';

const JezDragAbleContainer = ({children, title, visible}) => {
    const draggable = window.innerWidth >768;

    return (
        <Fragment>
            <div className={visible ? '' : 'out-off-screen'}>
                {draggable && (
                    <MovablePopover
                        id="examle-id"
                        moveTo="left"
                        backgroundColor="transparent"
                        content={<div className={'drag-able-container'}><div className={'headline'}>{title}</div><div className={'content'}>{children}</div></div>}
                        isVisible={true}
                    />
                )}

                {!draggable && (
                <div className={'mobile-container'}>
                    <div className={'headline'}>{title}</div><div className={'content'}>{children}</div>
                </div>)}
            </div>
        </Fragment>
    );
};

export default JezDragAbleContainer;
