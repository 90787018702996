import axios from "axios";
import React, {Fragment, useContext, useEffect, useState} from "react";
import JezGridImg from "../gridimg/gridimg";
import JezHeading from "../heading/heading";
import {Trans, useTranslation} from "react-i18next";
import {Grid, CircularProgress} from "@material-ui/core";
import JezButton from "../button/button";
import JezModal from "../modal/modal";
import AuthContext from "../../context/auth";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import JezTextEdit from "../textedit/textedit";
import InfoBox from "../infoBox/infoBox";
import {randomId} from "../../util/random";
import {BiBookHeart, BsTable} from 'react-icons/all';
import {toast} from "react-toastify";
import JezTextArea from "../textarea/textarea";
import JezTable from "../table/table";

const useStyles = makeStyles((theme) => ({
    spacer: {
        height: 15
    },
    noticeArea: {
        margin: '3px 0 15px 0'
    },
    field: {
        height: 20,
        margin: '3px 0',
        cursor: 'default',
        fontWeight: 'bold'
    },
    buttonPosition: {
        float: 'right'
    },
    headline: {
        margin: '15px 0 5px 0'
    },
    durationGroup: {
        textAlign: "center"
    },
    infoDuration: {
        textAlign: "center",
        fontSize: 16,
        margin: "15px 0 10px 0",
    },
    headlineBookedTimes: {
        margin: '8px 0 0 0',
        borderTop: '1px solid #b4ab6e',
        padding: '8px 0 0 0'
    },
    loaderContainer: {
        textAlign: 'center'
    },
    loader: {
        color: '#b4ab6e'
    },
    timeBox: {
        padding: '5px 10px',
        border: '1px solid #b4ab6e',
        color: '#b4ab6e',
        display: 'inline-block',
        margin: '5px 4px 0 0',
        cursor: 'pointer',
        '&.bookable:hover': {
            color: '#fff',
            background: '#b4ab6e'
        },
        '&.locked': {
            opacity: 0.5,
            cursor: 'default'
        }
    },
    durationBox: {
        padding: '5px 10px',
        border: '1px solid #b4ab6e',
        color: '#b4ab6e',
        display: 'inline-block',
        margin: '5px 4px 0 0',
        cursor: 'pointer',
        width: 80,
        '&.bookable:hover': {
            color: '#fff',
            background: '#b4ab6e'
        },
        '&.locked': {
            opacity: 0.5,
            cursor: 'default'
        }
    },
    bookButton: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        zIndex: 50,
        fontSize: 20,
        padding: '3px !important',
        lineHeight: '10px !important',
        '& svg': {
            fontSize: 20
        }
    },
    bookedButton: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        zIndex: 50,
        fontSize: 16,
        padding: '5px !important',
        lineHeight: '10px !important',
        '& svg': {
            fontSize: 16
        }
    },
    subHeadline: {
        textAlign: 'center',
        margin: '10px 0',
        fontSize: '16px',
        cursor: 'default'
    },
    modal: {
        width: '800px'
    },
    chancel: {
        float: "right"
    }
}));

const JezBookableHostGrid = ({filter, limit, center, searchAble}) => {
    /**
     * CSS
     */
    const classes = useStyles();

    limit = limit ? limit : 25;

    /**
     * STATES
     */
    const [collection, setCollection] = useState([]);
    const [infoOpen, setInfoOpen] = useState(false);
    const [bookable, setBookable] = useState(false);
    const [state, setState] = useState({uid: '', time: '', date: moment().format('YYYY-MM-DD'), customer: '', notice: '', step: 1, maxDuration: 0, unix: 0});
    const [isTimesLoading, setIsTimesLoading] = useState(false);
    const [isHostsLoading, setIsHostsLoading] = useState(true);

    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    /**
     * HOOKS
     */
    useEffect(() => {
        fetch();
    }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchBookData();
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () => {
        setIsHostsLoading(true);
        try {
            let searchFilter = [];

            for (let key in filter) {
                const item = filter[key];
                if (item instanceof Array) {
                    for (let entry of item) {
                        searchFilter.push(`filter[${key}]=${entry}`);
                    }
                } else {
                    searchFilter.push(`filter[${key}]=${item}`);
                }
            }

            searchFilter = searchFilter.length > 0 ? '&' + searchFilter.join('&') : '';

            const response = await axios.get(`/api/users/filter?limit=${limit}${searchFilter}`);
            //setCollection(response.data.filter((item) => { return item.profile.calender_id && item.profile.calender_enabled }));
            setCollection(response.data);
            setIsHostsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const openInfo = async (user) => {
        setInfoOpen(true);
        setIsTimesLoading(false);
        setState({...state, user: user, date: filter.date, step: 1, time: '', customer: '', notice: ''});
    }

    const selectDate = async (e) => {
        const mom = moment(e.target.value);
        setState({...state, date: mom.format('YYYY-MM-DD')});
        setIsTimesLoading(true);
    }

    const selectTime = (selectedItem) => {
        let max = 999;
        const list = [];
        for(let group of bookable) {
            for(let item of group) {
                list.push(item);
            }
        }

        for(let item of list) {
            if(!item.bookable && !item.empty && item.date.unix() > selectedItem.date.unix()) {
                max = (item.date.unix() - selectedItem.date.unix())/(60);
                break;
            }
        }
        setState({...state, time: selectedItem.date, step: 2, maxDuration: max});
    }

    const backToTimeSelection = () => {
        setState({...state, time: '', step: 1});
    }

    const backToCustomerSelection = () => {
        setState({...state, step: 3});
    }

    const backToDurationSelection = () => {
        setState({...state, step: 2});
    }

    const nextToReviewSelection = () => {
        setState({...state, step: 4});
    }

    const book = async () => {
        try {
            setState({...state, step: 5});
            let data = {...state, date: state.time};
            delete data.user;

            await axios.post(`/api/users/${state.user._id}/work/time/book`, data, {
                headers: {'x-auth-token': auth.token},
            });

            setState({...state, step: 6});
            toast.success(t('toast_hostess_successful_booked'));
        } catch(err) {
            console.log(err);
        }
    }

    const customerData = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const selectDuration = (time) => {
        setState({...state, duration: time, step: 3});
    }

    const fetchBookData = async () => {
        try {
            if (state.date && state.user && state.user._id) {
                const response = await axios.get(`/api/users/${state.user._id}/work/time?type=single&date=${state.date}`, {
                    headers: {'x-auth-token': auth.token},
                });
                let collection = {}
                for (let item of response.data) {
                    const mom = moment(item.date);
                    const hour = mom.format('DDHH');

                    if (!collection[hour]) {
                        collection[hour] = [];
                    }

                    collection[hour].push({...item, date: mom});
                }

                for (let index in collection) {
                    for(let ii = collection[index].length; ii < 4;  ii++) {
                        const dummyRaw = {...collection[index][ii - 1]};
                        const mom = moment(dummyRaw.date);
                        mom.add(15, "minutes");
                        const dummy = {...dummyRaw, date: mom, bookable: false};
                        collection[index].push(dummy);
                    }
                }

                setBookable(Object.values(collection));
            } else {
                setBookable(false);
            }
        } catch (err) {
            console.log(err);
        }

        setIsTimesLoading(false);
    }

    if(isHostsLoading) {
        return (<Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} className={classes.loaderContainer}>
                <div className={classes.spacer}/>
                <CircularProgress className={classes.loader}/>
                <div className={classes.spacer}/>
            </Grid>
        </Grid>);
    }

    return (<Fragment>
        <Grid center={center} container spacing={1}>
            {collection.map((item, index) => {
                if (item._id !== 0) {
                    return (
                        <JezGridImg
                            key={index}
                            image={`/api/hosts/${item._id}/avatar/single`}
                            onClick={ () => { openInfo(item); }}
                            username={item.username}
                            sextype={item.profile.sex_type}
                            birthyear={item.profile.birth_year}
                            actionButtons={<Fragment>
                                <JezButton title={t('button_tooltip_availability')} className={classes.bookButton} onClick={() => {
                                    openInfo(item)
                                }}><BiBookHeart/></JezButton>
                            </Fragment>}
                        />
                    )
                }
                return ''
            })}
            {collection.length === 0 && (
                <JezHeading center variant='h4'><Trans>host_search_empty_result</Trans></JezHeading>)}
        </Grid>

        <JezModal size={state.step === 3 || state.step === 4 ? 'md' : 'xs'} isOpen={infoOpen} onClose={() => {
            setInfoOpen(false)
        }} className={classes.modal}>
            {state.step === 6 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>booking_successful</Trans>
                        </JezHeading>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} className={classes.loaderContainer}>
                                <div className={classes.spacer}/>
                                <Trans>successful_booked</Trans>
                                <div className={classes.spacer}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.step === 5 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>do_booking</Trans>
                        </JezHeading>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} className={classes.loaderContainer}>
                                <div className={classes.spacer}/>
                                <CircularProgress className={classes.loader}/>
                                <div className={classes.spacer}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.step === 4 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>book_time</Trans>
                        </JezHeading>
                        <div className={classes.subHeadline}><Trans>text_check_hook_host</Trans></div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={3}/>
                            <Grid item xs={12} sm={12} md={3}>
                                <div className={classes.field}><Trans>label_host</Trans></div>
                                <div className={classes.field}><Trans>label_date</Trans></div>
                                <div className={classes.field}><Trans>label_time</Trans></div>
                                <div className={classes.field}><Trans>label_duration</Trans></div>
                                <div className={classes.field}><Trans>label_customer</Trans></div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <div className={classes.field}>: {state.user.username}</div>
                                <div className={classes.field}>: {state.time.format('DD.MM.YYYY')}</div>
                                <div className={classes.field}>: {`${state.time.format('HH:mm')} ${t('clock')}`}
                                  </div>
                                <div className={classes.field}>: {`${state.duration} ${t('label_minutes')}`} </div>
                                <div className={classes.field}>: {state.customer}</div>
                            </Grid>
                        </Grid>
                        {state.notice.length > 0 && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <JezHeading center variant='h4' className={classes.headline}>
                                    <Trans>label_notice</Trans>
                                </JezHeading>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {state.notice}
                            </Grid>
                            <br /><br />
                        </Grid>)}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <JezButton small className={'error'}
                                           onClick={backToCustomerSelection}><Trans>button_label_back</Trans></JezButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <JezButton small onClick={book}
                                           className={classes.buttonPosition}><Trans>button_label_book</Trans></JezButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.step === 3 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>headline_book_customer_data</Trans>
                        </JezHeading>

                        <JezTextEdit required type={'text'} name={'customer'} onChange={customerData} value={state.customer}
                                     label={t('input_label_customer')} placeholder={t('input_label_customer_placeholder')}/>
                        <JezTextArea name={'notice'} className={classes.noticeArea} onChange={customerData} placeholder={t('input_label_notice')}>{state.notice}</JezTextArea>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <JezButton small className={'error'}
                                       onClick={backToDurationSelection}><Trans>button_label_back</Trans></JezButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <JezButton disabled={state.customer.length === 0} small onClick={nextToReviewSelection}
                                       className={classes.buttonPosition}><Trans>button_label_next</Trans></JezButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.step === 2 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>headline_book_duration</Trans>
                        </JezHeading>
                        <div className={classes.infoDuration}><Trans>message_info_duration</Trans></div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={1}/>
                            <Grid item xs={12} sm={12} md={10}>
                                <div key={randomId()} className={classes.durationGroup}>
                                    {[30, 60, 120, 180, 240, 300].map((item) => (<div key={randomId()} title={t(state.maxDuration >= item ? 'tooltip_select_duration' : 'tooltip_time_not_bookable')} className={[classes.durationBox, state.maxDuration >= item ? 'bookable' : 'locked'].join(' ')} onClick={() => { if(state.maxDuration >= item) { selectDuration(item) } }}>{`${item} ${t('label_minutes')}`}</div>))}
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.spacer}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <JezButton small className={'error'}
                                   onClick={backToTimeSelection}><Trans>button_label_back</Trans></JezButton>
                    </Grid>
                </Grid>
            )}
            {state.step === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <JezHeading center variant='h4' className={classes.headline}>
                            <Trans>headline_working_times_bookable</Trans>
                        </JezHeading>
                        <JezTextEdit type={'date'} name={'select_date'} onChange={selectDate} value={state.date}
                                     label={t('input_label_date')} placeholder={t('input_label_date_placeholder')}/>
                        {!isTimesLoading && (<Fragment>
                            {bookable && bookable.length > 0 && (
                                <div className={classes.subHeadline}><Trans>text_choose_bookable_time</Trans></div>
                            )}
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={2}/>
                                <Grid item xs={12} sm={12} md={8}>
                                    {bookable && bookable.map((group) => (<div key={randomId()} className={'time-group'}>
                                        {group.map((item) => (
                                            <div key={randomId()} title={t(item.bookable ? 'tooltip_book_time' :'tooltip_not_book_time' )} onClick={() => {
                                                if (item.bookable) {
                                                    selectTime(item)
                                                }
                                            }}
                                                 className={[classes.timeBox, item.bookable ? 'bookable' : 'locked'].join(' ')}>{item.date.format('HH:mm')}</div>))}
                                    </div>))}
                                </Grid>
                            </Grid>
                            {!bookable && (
                                <InfoBox><Trans>info_select_a_day</Trans></InfoBox>
                            )}
                            {bookable && bookable.length === 0 && (
                                <InfoBox><Trans>info_nothing_time_available</Trans></InfoBox>
                            )}
                        </Fragment>)}
                        {isTimesLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} className={classes.loaderContainer}>
                                    <div className={classes.spacer}/>
                                    <CircularProgress className={classes.loader}/>
                                </Grid>
                            </Grid>
                        )}
                        <div className={classes.spacer}/>
                    </Grid>
                </Grid>
            )}

        </JezModal>
    </Fragment>);
}

export default JezBookableHostGrid;