import JezHeading from "../../../components/heading/heading";
import {Trans} from "react-i18next";
import JezSpacer from "../../../components/spacer/spacer";
import mask from "../../../assets/img/mask_76x27x96.png";
import JezHostGrid from "../../../components/hostgrid/hostgrid";
import React, {Fragment} from "react";
import {Container, makeStyles} from "@material-ui/core";
import Teaser from "../../../components/teaser/teaser";
import ModifyAblePageContent from "../../../components/ModifyAblePageContent/ModifyAblePageContent";
import ContentContainer from "../../../components/contentContainer/contentContainer";

const useStyles = makeStyles((theme) => ({
    maxWidth1170: {
        maxWidth: '1170px',
        margin: 'auto',
        position: "relative",
        zIndex: "500000"
    },
    black: {
        background: '#181818;',
        overflow: 'hidden'
    },
    padding: {
        padding: '50px 0 70px',
        position: 'relative'
    },
    splitter: {
        margin: '48px 0 0 0'
    },
    parallax1: {
        backgroundImage: 'url(http://coffeecreamthemes.com/themes/scent/site/images/parallax1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundAttachment: 'fixed',
        height: '200%',
        left: '0',
        opacity: '.25',
        overflow: 'hidden',
        position: 'absolute',
        top: '0',
        width: '100%',
    },
    tint: {
        height: '100%',
        top: '0',
        position: 'absolute',
        left: '0',
        width: '100%',
        backgroundImage: 'url(http://coffeecreamthemes.com/themes/scent/site/images/texture.png)',
    },
}));

const HostSection = () => {
    /**
     * CSS
     */
    const classes = useStyles();

    return (
        <div id={'ladies'} className={`${classes.padding} ${classes.black}`}>
            <div className={`${classes.parallax1}`}>

            </div>
            <div className={`${classes.tint}`}>

            </div>
            <JezHeading center variant='h2' className={classes.splitter}>
                <Trans>our_hosts</Trans>
            </JezHeading>
            <JezHeading center variant='h4'>
                <Trans>our_hosts_text</Trans>
            </JezHeading>
            <JezHeading center variant='h4' styles={{fontWeight: 'bold', fontSize: '24px'}}>
                <Trans>our_hosts_text2</Trans>
            </JezHeading>
            <JezSpacer icon={<img src={mask} alt='mask'/>}/>
            <ContentContainer><ModifyAblePageContent context={'content-before-home-hosts'} /></ContentContainer>
            <JezHostGrid limit={'6'} justify={'center'}/>
            <ContentContainer><ModifyAblePageContent context={'content-after-home-hosts'} /></ContentContainer>
        </div>
    );
}

export default HostSection;
