import React, {Fragment, useContext, useEffect, useState} from "react";
import AuthContext from "../../context/auth";
import JezBookableHostGrid from "../../components/bookableHostGrid/bookableHostGrid";
import {Trans, useTranslation} from "react-i18next";
import JezHeading from "../../components/heading/heading";
import {makeStyles} from "@material-ui/core/styles";
import JezBookableHostFilter from "../../components/bookableHostFilter/bookableHostFilter";
import JezContainer from "../../components/container/container";
import InfoBox from "../../components/infoBox/infoBox";
import axios from "axios";
import {isModerator} from "../../util/roles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    headline: {
        margin: '25px 0 10px 0'
    }
}));

const ModerationBookList = ({ history }) => {

    const {t} = useTranslation();

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);

    if (!auth || (auth && !isModerator(auth))) {
        history.push('/');
    }

    /**
     * CSS
     */
    const classes = useStyles();

    /**
     * STATE
     */
    const [apiAuthenticated, setApiAuthenticated] = useState({google: false});
    const [filter, setFilter] = useState({date: moment().format('YYYY-MM-DD')});

    /**
     *
     * Hooks
     */
    useEffect(() => {
        checkGoogleAPI();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkGoogleAPI = async () => {
        try {
            const response = await axios.get(`/api/system/google/is/auth`, {
                headers: {'x-auth-token': auth.token},
            });

            setApiAuthenticated({...apiAuthenticated, google: response.data.auth});
        } catch(err) {
            console.log(err);
        }
    }

    return (<Fragment>
        <JezContainer padding style={{margin: '96px auto 32px auto'}} header={t('header_book_host')}>
            <JezHeading center variant='h4' className={classes.headline}>
                <Trans>headline_bookable_hosts_filter</Trans>
            </JezHeading>
            <Fragment>
                <JezBookableHostFilter setFilter={setFilter} />
                <JezHeading center variant='h4' className={classes.headline}>
                    <Trans>headline_bookable_hosts</Trans>
                </JezHeading>
                <JezBookableHostGrid filter={filter}/>
            </Fragment>
        </JezContainer>
    </Fragment>);
}

export default ModerationBookList;

