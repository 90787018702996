import axios from "axios";
import moment from 'moment';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import AuthContext from '../../context/auth';
import SocketContext from '../../context/socket';
import JezButton from "../button/button";
import JezHeading from "../heading/heading";
import JezTextEdit from "../textedit/textedit";
import CloseIcon from '@material-ui/icons/Close';
import './messageBox.scss';
import { Trans } from 'react-i18next';
import {useHistory} from 'react-router-dom';
import { RiMessengerFill } from 'react-icons/ri';

//rete

const JezMessageBox = () => {
    const {t} = useTranslation();

    const { auth } = useContext(AuthContext);
    const {socket} = useContext(SocketContext);

    const [messages, setMessages] = useState([]);
    const [conservations, setConservations] = useState([]);
    const [state, setState] = useState({content: '', user: ''});
    const [newMessagePosition, setNewMessagePosition] = useState(-1);
    const [view, setView] = useState(false);

    const history = useHistory();

    const stateRef = useRef();
    stateRef.current = state;

    const fetchMessages = async (uid, doDecrement) => {
        try {
            let response = await axios.get('/api/messages/' + (uid ? `?user=${uid}` : ''), { headers: { 'x-auth-token': auth.token } });
            let decrement = 0;

            if(uid) {
                decrement = parseInt(document.getElementById(`messages-${uid}`).getAttribute('data-unread-counter'));
                await axios.post('/api/messages/read', {user: uid},  { headers: { 'x-auth-token': auth.token } });
                for(const mesIndex in response.data.messages) {
                    if(!response.data.messages[mesIndex].read) {
                        setNewMessagePosition(mesIndex);
                        break;
                    }
                }
            }

            hasUnread(response.data.conservations, doDecrement ? decrement : 0);

            const currentHost = await getHost();

            if(currentHost && response.data.conservations.filter((item) => item.username === currentHost.username).length === 0) {
                response.data.conservations.push({
                    unread: 0,
                    username: currentHost.username,
                    _id: currentHost._id
                });
            } else {
                const index = response.data.conservations.findIndex((item) => { return item._id === uid; });

                if(index >= 0) {
                    response.data.conservations[index].unread = 0;
                }
            }



            setMessages(response.data.messages);
            setConservations(response.data.conservations);
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * HOOKS
     */
    useEffect(() => {
        fetchMessages(state.user);

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const dom = document.getElementById('messages-list');
        if(dom && dom.children.length > 0) {
            dom.scrollTop = 1000000;
        }
    }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * HOOKS
     */
    useEffect(() => {
        if (socket !== null) {
            history.listen((location) => {
                reload();
            });
            socket.on(`messages/${auth.id}`, function (event) {
                const user = stateRef.current.user ? stateRef.current.user : ''; //isVisible() ? stateRef.current.user : '';
                fetchMessages(user);
                incrementUnreadMessages(user);
            });
        }

        return () => {
            if (socket !== null) {
                socket.off(`hosts/${auth.id}/message`);
            }
        };
    }, [socket, history]); // eslint-disable-line react-hooks/exhaustive-deps

    const openMessenger = () => {
        setView(!view);

        if(!view) {
            setState({...state, user: ''});
            setMessages([]);
        }

        // const user = stateRef.current.user ? stateRef.current.user : '';
        //
        // if(user) {
        //     fetchMessages(user, true);
        // }
    };

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const changeUser = (e) => {
        setState({ ...state, user: e.target.value });
        fetchMessages(e.target.value, true);
    };

    const closeMessengerClick = () => {
        setView(false);
    };

    const submit = async (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('content', state.content);
        data.append('user', state.user);

        try {
            await axios.post(`/api/messages/`, data, {
                headers: {'x-auth-token': auth.token}
            });

            fetchMessages(state.user);

            setState({ ...state, content: '' });
            toast.success(t('message_send_success'));
        } catch (errors) {
            for(let err of errors.response.data.errors) {
                toast.error(t(err.msg));
            }
        }

        return false;
    };

    const randomId = () => {
        return Math.floor(Math.random() * 10000001);
    };

    const hasUnread = function(conservations, decrement) {
        const dom = document.getElementById('messages-unread');

        if(dom) {
            let count = 0;
            for(let user of conservations) {
                count += user.unread;
            }

            count -= decrement;

            dom.style.display = count > 0 ? 'block' : 'none';
            dom.textContent = count;
        }
    };

    // const isVisible = () => {
    //     if(document.getElementById('messenger-container') && !document.getElementById('messenger-container').classList.contains('view')) {
    //         document.getElementById('user-nothing').checked = true;
    //         //setState({content: '', user: ''});
    //
    //         return false;
    //     }
    //
    //     return true;
    // };

    const incrementUnreadMessages = (uid) => {

        let count = 0;
        if(state.user !== uid) {
            const userDom = document.getElementById(`messages-${uid}`);
            let userCount = parseInt(userDom.getAttribute('data-unread-counter'));
            userDom.setAttribute('data-unread-counter', userCount + 1);
            count++;
        }

        const dom = document.getElementById('messages-unread');

        if(dom) {
            dom.textContent = parseInt(dom.textContent) + count;
        }
    };

    const getHost = async () => {
        const data = window.location.href.match(/\/host\/([^/]+)\/profile/);

        if (data && data.length === 2) {
            try {
                const response = await axios.get(`/api/hosts/${data[1]}`);

                return response.data;

            } catch (errors) {
                for (let err of errors.response.data.errors) {
                    toast.error(t(err.msg));
                }
            }
        }

        return false;
    };

    const reload = () => {
        fetchMessages(stateRef.current.user);
    };

    return (<>
        <span className={'toogle-messenger'} onClick={(e) => openMessenger()}>
            <span id={'messages-unread'} />
            <RiMessengerFill />
        </span>
        <div id={'messenger-container'} className={view ? 'view' : ''}>
        {conservations.length === 0 && (<div className={'messenger-no-conservations'}><Trans>messenger_no_conservations</Trans></div>)}
        {conservations.length > 0 && (
        <form className='message-box' onSubmit={(e) => submit(e)}>
            <div id={'messenger-reload'} onClick={reload} />
            <JezHeading center={'true'} variant='h4' className={'header'}>
                <Trans>headline_messinger</Trans>
                <div id={'button-close-messenger'} title={t('title_close')} onClick={closeMessengerClick}><CloseIcon /></div>
            </JezHeading>
            <ul className='conservation-list'>
                <li style={{display: 'none'}}>
                    <input type='radio' name='user' id={`user-nothing`} value={''} />
                </li>
                {conservations.map((user) => (
                    <li key={randomId()} className={'message-item'}>
                        {state.user === user._id && (<input type='radio' name='user' id={`user-${user._id}`} value={user._id} defaultChecked/>)}
                        {state.user !== user._id && (<input required onChange={(e) => changeUser(e)} type='radio' name='user' id={`user-${user._id}`} value={user._id} />)}
                        <label id={`messages-${user._id}`}  className={'user-list-item'} data-unread-counter={user.unread} htmlFor={`user-${user._id}`}>
                            {user.username}
                            {user.unread > 0  && (<span className={'count-unread-messages'}>{user.unread}</span>)}
                        </label>
                    </li>
                ))}
            </ul>
            <div className='messages-context'>
                <ul id={'messages-list'} className='messages-list'>
                    {messages.map((message, index) => (
                        <li key={randomId()}>
                            {!message.read && parseInt(newMessagePosition) === parseInt(index) && message.from !== auth.id && (<div className={'new-message'}><span className={'new-message-label'}><Trans>new_message</Trans></span></div>)}
                            <div className={`${message.from === auth.id ? 'own-message' : 'other-message'}`}>{message.content}<br /><span className={'timestamp'}>{moment(message.created).format('DD.MM.YYYY HH:mm')}</span></div>
                        </li>
                    ))}
                </ul>
                {state.user && (
                <div className='message-input-context'>
                    <form noValidate autoComplete="off">
                    <JezTextEdit
                        autoComplet="off"
                        name="content"
                        required
                        onChange={(e) => change(e)}
                        id="content"
                        type="text"
                        value={state.content}
                    />
                    </form>
                    <JezButton type='submit' margin center>
                        <Trans>send_message</Trans>
                    </JezButton>
                </div>
                )}
            </div>
        </form>
        )}
        </div>
        </>); // eslint-disable-line jsx-a11y/anchor-is-valid
};

export default JezMessageBox;
