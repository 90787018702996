import JezHeading from "../heading/heading";
import JezButton from "../button/button";
import React, {Fragment, useEffect, useRef, useState} from "react";
import "./gdprPermission.scss"
import {Trans, useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import JezCheckbox from "../checkbox/checkbox";
import {getCookie, setCookie} from "../../util/cookie";

const GdprPermission = ({context, type, target, onReject, onAccept}) => {
    const {t} = useTranslation();

    /**
     * States
     */
    const [doSave, setDoSave] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [display, setDisplay]  = useState(false);
    const [element, setElement] = useState(null);

    /**
     * Ref
     */
    const acceptedReference = useRef();
    acceptedReference.current = accepted;

    /**
     * Hooks
     */
    useEffect(() => {
        inject(target)
    }, [target]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(element) {
            element.props.onClick();
        }
    }, [accepted]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const change = (e) => {
        setDoSave(e.target.checked);
    }

    const close = () => {
        setDisplay(false);
    }

    const hasPermission = () => {
        return parseInt(getCookie(`gdpr_${context.toLowerCase()}`)) === 1;
    }

    const inject = (item) => {
            const elem = React.cloneElement(item, {onClick: () => {
                if(hasPermission() || acceptedReference.current) {
                    item.props.onClick();
                } else {
                    setDisplay(true);
                }
            }});
        setElement(elem);
    }

    const reject = () => {
        if(onReject) {
            onReject();
        }

        close();
    }

    const accept = () => {
        if(doSave) {
            setCookie(`gdpr_${context.toLowerCase()}`, 1);
        }

        setAccepted(1);

        if(onAccept) {
            onAccept();
        }

        close();
    }

    return (
        <Fragment>
            {element}
        {display && (
            <div className={'gdpr-external-media-permission-container'}>
                <div className={'gdpr-external-media-permission-box'}>
                    <JezHeading><Trans>gdpr_headline</Trans></JezHeading>
                    <div className={'gdpr-external-media-message'}>
                        {t('gdpr_message').replace('%context%', context).replace('%type%', t(type))}<br />
                        <small>({t('gdpr_message_info').replace('%context%', context)})</small><br />
                        <Trans>gdpr_message_question</Trans>
                    </div>

                    <div className={'gdpr-save-submit'}>
                        <JezCheckbox label={t('gdpr_message_not_ask_again')} checked={doSave} onChange={change}/>
                    </div>

                    <div className={'gdpr-actions'}>
                        <JezButton small secondary type={'error'} onClick={reject}><Trans>btn_gdpr_reject</Trans></JezButton>
                        <JezButton small secondary id={'video-embed-submit'} onClick={accept}><Trans>btn_gdpr_accept</Trans></JezButton>
                    </div>
                </div>
            </div>)}
        </Fragment>
    );
}

GdprPermission.propTypes = {
    context: PropTypes.oneOf(['Youtube', 'Google']),
    type: PropTypes.oneOf(['Video', 'Map']),
    target: PropTypes.object.isRequired
};

GdprPermission.defaultProps = {
    context: null,
    type: null
};

export default GdprPermission;