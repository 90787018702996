import React, {Fragment, useContext, useEffect, useState} from 'react'
import AuthContext from "../context/auth";
import {Trans, useTranslation} from "react-i18next";
import {useJezios} from "../util/jezios";
import JezHeading from "../components/heading/heading";
import JezItem from "../components/listItem/listItem";
import JezContainer from "../components/container/container";
import JezSpacer from "../components/spacer/spacer";
import {rand} from "../util/tools";

const News = ({ history }) => {
    const [state, setState] = useState([]);

    /**
     * CONTEXT
     */
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    const request = useJezios(auth, t);

    const fetch = async () => {
        const response = await request.get('/news');

        if(response) {
            setState(response.data.data);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <JezContainer maxWidth={'lg'} padding style={{margin: '96px auto 32px auto'}}>
            <JezHeading center variant='h2'>
                <Trans>news_label</Trans>
            </JezHeading>
            {state.map((item, index)  => (
                <Fragment key={rand()}>
                <JezItem section={'news'} content={item.content} title={item.name} images={item.images} id={item._id} expanded={false} collapseable={false} imageSize={item.imagesize ? item.imagesize : 0}/>
                {index < state.length - 1 && (<JezSpacer />)}
            </Fragment>
            ))}
        </JezContainer>
    );
};

export default News;