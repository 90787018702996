import React, { useContext, useState } from 'react';
import {AiOutlineMobile, AiOutlinePhone, AiOutlineWhatsApp} from "react-icons/all";
import {NavLink} from 'react-router-dom';
import AuthContext from '../../context/auth';
import JezNavLinks from '../navlinks/navlinks';
import Collapse from '@material-ui/core/Collapse';
import './navbar.scss';
import JezMessageBox from '../messageBox/messageBox';
import { Fragment } from 'react';

const JezNavbar = ({ title }) => {
    const { auth } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
        <div className='nav-wrap'>
            <div className='nav-container'>
                <div className='nav-title'>
                    <NavLink activeClassName='active' exact to='/'>
                        {title}
                    </NavLink>
                </div>
                {auth ? (
                    <div className='login-nav-numbers nav-numbers' >
                        <span className='nav-number-item'>
                            <a href="phone:+436641947614">
                                <AiOutlineMobile/> +43 664 194 76 14
                            </a>
                            <a href="https://wa.me/436641947614?text=Hallo, ich komme von JEzzABER.com" target="_blank" rel="noreferrer"><AiOutlineWhatsApp/></a>
                        </span>
                    </div>
                ) : (

                    <div className='nav-numbers' >
                        <span className='nav-number-item'>
                            <a href="phone:+436641947614">
                                <AiOutlineMobile/> +43 664 194 76 14
                            </a>
                            <a href="https://wa.me/436641947614?text=Hallo, ich komme von JEzzABER.com" target="_blank" rel="noreferrer"><AiOutlineWhatsApp/></a>
                        </span>
                    </div>
                )}
                <button className={'toogle-mobile-menu'} onClick={(e) => setOpen(!open)}>
                    <span className={'icon-bar'}></span>
                    <span className={'icon-bar'}></span>
                    <span className={'icon-bar'}></span>
                </button>
                <nav id='main' className={open ? 'open' : ''}>
                    {auth && (
                        <Fragment>
                            <JezMessageBox />
                        </Fragment>
                    )}
                    <div className='nav-desktop'>
                        <JezNavLinks />
                    </div>
                    <div className='nav-mobile' >
                        <Collapse in={open} timeout='auto' unmountOnExit onClick={(e) => setOpen(!open)}>
                            <JezNavLinks />
                        </Collapse>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default JezNavbar;
